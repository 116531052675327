import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h5 className="text-center"> Privacy Policy</h5>
      <p>
        THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC
        CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT, 2000 AND THE RULES
        MADE THEREUNDER AND THE PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS /
        RECORDS IN VARIOUS STATUTES AS AMENDED FROM TIME TO TIME. THIS PRIVACY
        POLICY DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
        THIS PRIVACY POLICY IS A LEGALLY BINDING DOCUMENT BETWEEN YOU AND RootRS INC (BOTH TERMS DEFINED BELOW). THE TERMS OF THIS PRIVACY
        POLICY WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME (DIRECTLY OR
        INDIRECTLY IN ELECTRONIC FORM, BY CLICKING ON OR BY THE USE OF THE
        WEBSITE OR BY OTHER MEANS) AND WILL GOVERN THE RELATIONSHIP BETWEEN YOU
        AND Root RootRS FOR YOUR USE OF THE WEBSITE (DEFINED BELOW). THIS
        DOCUMENT IS PUBLISHED AND SHALL BE CONSTRUED IN ACCORDANCE WITH THE
        PROVISIONS OF THE INFORMATION TECHNOLOGY (REASONABLE SECURITY PRACTICES
        AND PROCEDURES AND SENSITIVE PERSONAL DATA OF INFORMATION) RULES, 2011
        UNDER INFORMATION TECHNOLOGY ACT, 2000 AS AMENDED FROM TIME TO TIME;
        THAT REQUIRE PUBLISHING OF THE PRIVACY POLICY FOR COLLECTION, USE,
        STORAGE AND TRANSFER OF SENSITIVE PERSONAL DATA OR INFORMATION. PLEASE
        READ THIS PRIVACY POLICY CAREFULLY. BY USING THE WEBSITE, YOU INDICATE
        THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY. IF YOU DO
        NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT USE THIS
        WEBSITE.
      </p>
      <p>
        By providing us your Information/information relating to the end users
        or by making use of the facilities provided by the Website, you hereby
        consent to the collection, storage, processing and transfer of any or
        all of Your Personal Information and Non-Personal Information by Root
        RootRS as specified under this Privacy Policy. You further agree
        that such collection, use, storage and transfer of Your Information
        shall not cause any loss or wrongful gain to you or any other person.
        RootRS INC is concerned about the privacy of the data and
        information of users (including sellers and buyers/customers whether
        registered or non-registered) accessing, offering, selling or purchasing
        products or services on RootRS INC’s website and otherwise doing
        business with RootRS INC. The terms "We" / "Us" / "Our"
        individually and collectively refers to RootRS INC and the terms
        "You" /"Your" / "Yourself" refers to the users. This Privacy Policy is a
        contract between You and RootRS INC whose Website You use or
        access or You otherwise deal with. Our privacy policy is subject to
        change at any time without notice. To make sure you are aware of any
        changes, please review this policy periodically. By visiting this
        Website, you agree to be bound by the terms and conditions of this
        Privacy Policy. If you do not agree please do not use or access our
        Website. By mere use of the Website, you expressly consent to our use
        and disclosure of your personal information in accordance with this
        Privacy Policy. This Privacy Policy is incorporated into and subject to
        the Terms of Use.
      </p>
      <h5>Who we are?</h5>
      <p>
        RootRS INC, is introducing a platform that empowers NRIs to
        effortlessly care for their Indian ties. Seamlessly book services for
        family, friends, and your loved ones from anywhere in the world. From
        medical assistance to property management, Root ensures reliable and
        quality care. Stay close, worry less, and nurture your relationships and
        assets with Root's global connectivity. Your bridge to staying
        connected, even from afar. with office at 401, Liberty Avenue STE
        2,Jersey City, New Jersey, 07307.
      </p>
      <p>
        Any reference to RootRS INC in this policy refers to RootRS INC.
      </p>
      <p>
        RootRS INC is in the business of offering certain services to
        residents of India where bookings for the same are undertaken by users
        in the United States of America. The services are fulfilled by third
        party service providers.
      </p>
      <p>
        <a href="https://rootrs.com"> www.rootrs.com</a> is the website owned by
        RootRS INC.
      </p>
      <p>
        This Privacy Policy describes how we treat personal data we collect on
        <a href="https://rootrs.com"> www.rootrs.com</a> , our social media and
        direct contact channels. This applies to visitors to our website, people
        interacting or placing orders through other social networking media or
        any other medium whatsoever and to our online/offline customers. By
        visiting and/or using our website, and by interacting, sharing
        information or placing order with us through any other medium you agree
        to this Privacy Policy.
      </p>
      <p>
        Please read our privacy policy carefully to get a clear understanding of
        how we collect, use, protect or otherwise handle your Personally
        Identifiable Information in accordance with our website.
      </p>
      <p>
        <i>You hereby represent to RootRS INC that:</i>
      </p>
      <ul>
        <li>
          The Information you provide to RootRS INC from time to time is
          and shall be authentic, correct, current and updated and You have all
          the rights, permissions and consents as may be required to provide
          such Information to RootRS INC, including on behalf of end
          users that you may book our Services/Products for.
        </li>
        <li>
          Your providing the Information to RootRS INC and RootRS
          INC's consequent storage, collection, usage, transfer, access or
          processing of the same shall not be in violation of any third-party
          agreement, laws, charter documents, judgments, orders and decrees.
        </li>
        <li>
          RootRS INC’s officers, directors, contractors or agents shall
          not be responsible for the authenticity of the Information that You or
          any other user provide to RootRS INC. You shall indemnify and
          hold harmless RootRS INC’s officers, directors, contracts or
          agents and any third party relying on the Information provided by You
          in the event You are in breach of this Privacy Policy including this
          provision and the immediately preceding provision above.
        </li>
        <li>
          Your Information will primarily be stored in electronic form however
          certain data can also be stored in physical form. We may enter into
          agreements with third parties (in or outside of India) to store or
          process your information or data. These third parties may have their
          own security standards to safeguard your information or data and we
          will on commercial reasonable basis require from such third parties to
          adopt reasonable security standards to safeguard your information /
          data.
        </li>
      </ul>
      Our commitment and obligations to you
      <p>
        We take the collection, usage and security of your personal data
        seriously. We can only use your personal data under law if we have a
        good reason for doing so. The law provides examples of those reasons.
        These include:
      </p>
      <ul>
        <li> To perform or fulfil a contract we have with you; or</li>
        <li> If we have a legal duty; or</li>
        <li> If it is within our legitimate business interest; or</li>
        <li> If there is a public interest reason for doing so; or</li>
        <li> If you have given your consent.</li>
        <li> How we share your personal data</li>
      </ul>
      <p>
        We take your privacy very seriously and we’ll only share your
        information where:
      </p>
      <ul>
        <li>
          We need to for the purposes of providing you with products or services
          you have requested;
        </li>
        <li>
          We have a public or legal duty to do so e.g., to assist with detecting
          fraud and tax evasion, economic crime prevention, regulatory
          reporting, litigation or defending legal rights.
        </li>
        <li>
          We have a legitimate reason for doing so e.g., to manage risk, or
          assess your suitability for services; or
        </li>
        <li>
          We have asked you for your permission to share it, and you’ve agreed.
        </li>
      </ul>
      <h5>How do we use your personal data?</h5>
      <ul>
        <li>
          To personalize your experience and to allow us to deliver the type of
          content and product offerings in which you are most interested.
        </li>
        <li> To improve our website in order to better serve you.</li>
        <li>
          To allow us to better service you in responding to your customer
          service requests.
        </li>
        <li>
          To administer a contest, promotion, survey or other site feature(s).
        </li>
        <li> To process your transactions faster.</li>
        <li> To ask for ratings and reviews of services or products</li>
        <li>
          To follow up with them after correspondence (live chat, email or phone
          inquiries)
        </li>
        <li> Types of personal data we collect about you.</li>
      </ul>
      <h5>We collect the following personal data:</h5>
      <ul>
        <li> Name</li>
        <li>
          Contact details of customers or customers connected parties (e.g.,
          families/friends etc)
        </li>
        <li> Demographic data (including but not limited to date of birth)</li>
        <li> Phone numbers</li>
        <li> E-mail address</li>
        <li>
          Name and contact details of authorised persons which you consent for
          us to contact regarding the delivery of Services/Products
        </li>
        <li> Financial data (for payment)</li>
        <li> Technical Information (IP / Cookies)</li>
        <li> Location Data</li>
        <li> User login data</li>
        <li> Legal data (e.g., power of attorney)</li>
        <li> Any other data relevant to product or service requested.</li>
      </ul>
      <h5>When do we collect personal data about you?</h5>
      <p>You may give us information about you when you:</p>
      <ul>
        <li>
          fill in forms on our website
          <a href="https://rootrs.com"> www.rootrs.com</a> ("our site") or
        </li>
        <li> by corresponding with us by phone, e-mail or otherwise</li>
      </ul>
      <p>
        This includes information you provide when you subscribe to our
        services, complete a survey, sign up to a newsletter, post material on
        our site, report a problem with our site, request further services.
      </p>
      <h5>What Information we collect about you?</h5>
      <p>
        With regard to each of your visits to our site we may automatically
        collect the following information:
      </p>
      <ul>
        <li>
          technical information, including the Internet protocol (IP) address
          used to connect your computer to the Internet, your login information,
          browser type and version, time zone setting, browser plug-in types and
          versions, operating system and platform; and
        </li>
        <li>
          information about your visit, including the full Uniform Resource
          Locators (URL) clickstream to, through and from our site (including
          date and time); products you viewed or searched for, page response
          times, download errors, length of visits to certain pages, page
          interaction information (such as scrolling, clicks, and mouse-overs),
          cookies and methods used to browse away the page and any phone number
          used to call our customer service number.
        </li>
      </ul>
      <h5>Information received from other sources:</h5>
      <p>
        We may receive information from other sources, such as from your
        authorised representatives for the purpose of providing services to you.
      </p>
      <p>
        The information received may include names, address details, authorised
        representative information and any other information essential to
        providing the Services to you.
      </p>
      <h5>
        IMPORTANT: For data privacy reasons, we will only collect information
        from you directly or via your authorised representative who has
        confirmed that they are authorised to provide this information on your
        behalf/on behalf of the end users. We shall not be under any
        circumstance liable for any claims made by an end user to the extent
        that the booking was made without their consent. In such scenario, RootRS INC shall be entitled to be indemnified for all losses, damages
        etc that have arisen on account of such claims by end users.
      </h5>
      <h5>Cookies</h5>
      <p>
        We may obtain information about your general internet usage by using
        cookie files stored on your computer or device (“cookies”). Cookies are
        text files containing small amounts of information which are downloaded
        to your computer or device when you visit a website. They help us to
        improve our site and to deliver a better and more personalised service.
      </p>
      <p>
        We may use both “session” cookie and “persistent” cookies on the
        website. Session cookies will be deleted from your computer when you
        close your browser. Persistent cookies will remain stored on your
        computer until deleted, or until they reach a specified expiry date.
      </p>
      <p>
        We will use the session cookies to keep track of you whilst you navigate
        the website; keep track of your bookings; prevent fraud and increase
        website security; and other uses. We will use the persistent cookies to
        enable our website to recognise you when you visit; keep track of your
        preferences in relation to your use of our website; and other uses.
      </p>
      <p>Our payment services providers may also send you cookies.</p>
      <p>
        You may disable cookies by changing the settings on your browser.
        However, if you do so, this will affect your enjoyment of our site and
        some of the site features that make your experience more efficient may
        not function properly.
      </p>
      <h5>E-mail marketing and newsletters</h5>
      <p>
        We may make suggestions and recommendations to you that we think may be
        of interest to you or members of your team / organisation. We will only
        contact you by electronic means (email) with information about services
        similar to those which you have shown an interest in.
      </p>
      <p>
        We may also use your data, or permit selected third parties to use your
        data, to provide you with information about services which may be of
        interest to you and we or they may contact you about these by electronic
        means only if you have consented to this.
      </p>
      <p>
        You have the right to ask us not to process your information for
        marketing purposes. You can exercise your right to prevent such
        processing by ticking the relevant boxes on the forms we use to collect
        your information, or you can opt out of receiving future marketing
        communications from us at any time by following the directions contained
        in the marketing email to unsubscribe.
      </p>
      <p>
        We do not disclose information about identifiable individuals to our
        advertisers, but we may provide them with aggregate information about
        our users. We may also use such aggregate information to help
        advertisers reach the kind of audience they want to target. We may make
        use of the personal data we have collected from you to enable us to
        comply with our advertisers’ wishes by displaying their advertisement to
        that target audience.
      </p>
      <h5>Third parties</h5>
      <p>We may share with third parties, including:</p>
      <ul>
        <li>
          business partners, suppliers and subcontractors for the performance of
          any contract we enter into with them or you.
        </li>

        <li>
          fraud prevention agencies who will use it to prevent fraud and
          money-laundering and to verify your identity.
        </li>

        <li>
          provided you have consented; marketing, market research, advertisers
          and advertising networks that require the data to select and serve
          relevant advertisements to you and others.
        </li>

        <li>
          analytics and search engine providers that assist us in the
          improvement and optimisation of the website.
        </li>

        <li>
          any member of our group, which means our subsidiaries, our ultimate
          holding company and its subsidiaries for the purposes set out above.
        </li>

        <li>
          in the event that we buy or sell any business or assets, including the
          sale of an individual website owned by us, in which case we may
          disclose your personal data to the prospective seller or buyer of such
          business or assets; and
        </li>

        <li>
          if RootRS INC or substantially all of its assets are acquired
          by a third party, in which case personal data held by it about its
          members and customers will be one of the transferred assets.
        </li>
      </ul>
      <h5>Sharing aggregated or anonymised data</h5>
      <p>
        Where we have made your information anonymous, we may share it with
        partners such as research groups, universities, advertisers or connected
        sites.
      </p>
      <h5>Where do we store your personal data?</h5>
      <p>
        The data that we collect from you may be transferred to, and stored at,
        a destination outside India. It may also be processed by staff operating
        outside India who work for us or for one of our suppliers or business
        partners. By submitting your personal data, you agree to this transfer,
        storing and/or processing. We will take all steps reasonably necessary
        to ensure that your data is treated securely and in accordance with this
        privacy policy.
      </p>
      <h5>Security of your personal data</h5>
      <p>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we will do our best to protect your personal
        data, we cannot guarantee the security of the data transmitted to our
        site; any transmission of your data is at your own risk. Once we have
        received your information, we will use strict procedures and security
        features to try to prevent unauthorised access.
      </p>
      <p>
        Our site may, from time to time, contain links to and from the websites
        of our member and associate member organisations, advertisers and
        affiliates. If you follow a link to any of these websites, please note
        that these websites have their own privacy policies and that we do not
        accept any responsibility or liability for these policies. Please check
        these policies before you submit any personal data to these websites.
      </p>
      <h5>How long we keep your information?</h5>
      <p>
        We will retain your personal information for as long as you use our
        services and for a reasonable time thereafter. After you have terminated
        your use of our services, we will retain your personal information for
        up to 30 - 90 days and thereafter may store it in an aggregated and
        anonymised format.
      </p>
      <h5>Grievance Officer</h5>
      <p>
        In accordance with Information Technology Act 2000 and rules made there
        under, the name and contact details of the Grievance Officer are:
      </p>
      <p>Venkata Krishnan Ganesan</p>
      <a href="tel:+19176227950" className="text-decoration-none">
        <p>Call: +1 917-622-7950</p>
      </a>
      <a href="mailto:info@root.delivery" className="text-decoration-none">
        <p>email: info@root.delivery</p>
      </a>
      <h5>Changes to our Privacy Policy</h5>
      <p>
        Any changes we may make to the Privacy Policy in the future will be
        posted on this page and, where appropriate, notified to you by e-mail.
        Please check this page frequently to see any updates or changes to this
        Privacy Policy.
      </p>
      <h5>Jurisdiction</h5>
      <p>
        If you choose to visit the website, your visit and any dispute over
        privacy is subject to this Policy and the website's terms of use. In
        addition to the foregoing, any disputes arising under this Policy shall
        be governed by the laws of state of New Jersey & Federal States of
        America.
      </p>
      <h5>Contact</h5>
      <p>
        Questions, comments and requests regarding this privacy policy are
        welcome and should be addressed to info@Root Delivery INC.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
