import React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import AppLinkButtons from "./AppLinkButtons/AppLinkButtons";
// import $ from jQuery;
// import Header_Logo from "../Logos/Root - Horizontal Logo Normal.png";

export const Header = ({ showModal }) => {
  //   $("#navbarSupportedContent").on('show.bs.collapse', function() {
  //     $('a.nav-link').click(function() {
  //         $("#navbarSupportedContent").collapse('hide');
  //     });
  // });

  const modalRef = useRef(null);

  const handleOpenModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  return (
    <>
      <div className="navbar navbar-expand-lg fixed-top bg-light">
        <div className="container">
          {/* logo */}

          <Link to={"/"} className="navbar-brand">
            <span className="text-primary flex-shrink-0 ">
              <img
                src="./VerticalLogo.png"
                // src="./logo.svg"
                width="120px !important"
                height="120px !important"
                alt=""
              />
              {/* <img src="./logo.svg" width="50%" alt="" /> */}
              {/* <img src={Header_Logo} width="3%" alt="" className="" /> */}
            </span>
          </Link>
          {/* btn -1 */}
          <div className="d-none d-sm-block  d-lg-none p-3 mt-0 ms-auto">
            {/* <Link to="/about" className="w-100 text-center pull-left">
              About
            </Link> */}
            {/* <Link to="/form" className="btn btn-primary">
              <i className="ai-edit fs-xl me-2 ms-n1"></i>
              Sign up as early users!
            </Link> */}
          </div>

          {/* hambuger toggle for mobile nav */}
          <button
            className="navbar-toggler d-sm-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-expanded="false"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* mobile nav block */}

          <nav className="collapse navbar-collapse mt-0 ms-auto" id="navbarNav">
            {/* btn-2 */}

            <div className="ms-auto">
              <span>
                {/* <Link to="/about" className="w-100 text-center d-lg-block pull-right ">
              About
            </Link> */}
              </span>
            </div>

            {/* <div className="d-sm-block d-md-block p-3">
              <Link to="/form" className="btn btn-primary w-100">
                <i className="ai-edit fs-xl me-2 ms-n1"></i>
              Sign up as early users!
              </Link>
            </div> */}
            <div className="d-flex justify-content-center align-items-center d-lg-none d-block">
              <AppLinkButtons small={true} />
              {/* 
              <Link to="/form">
                <div className=" p-3 mt-0 mx-auto z-5">
                  <button
                    type="button"
                    className="btn  btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#getAppLink"
                    // onClick={handleShow}
                  >
                    {" "}
                    <i className="ai-edit fs-xl me-2 ms-n1"></i>Sign up as early
                    users!
                  </button>
                </div>
              </Link> */}
            </div>
          </nav>
        </div>
      </div>
      <div className="next-element"></div>
    </>
  );
};
