import React from "react";
import { Link } from "react-router-dom";
import Services from "../components/Services/Services";
import "../Pages/style.css";
import { WorkButton } from "../components/WorkButton/WorkButton";
import BannerComponent from "../components/BannerComponent/BannerComponent";
import HomeSwiperModule from "../components/SwiperModule/SwiperModule";

// import play_store from "../play-store-logo.png";
import { Col, Row } from "react-bootstrap";
import AppLinkButtons from "../components/AppLinkButtons/AppLinkButtons";

const Homepage = () => {
  
  return (
    <>
      {/* 01.HOMEBANNER */}
      {/* <BannerComponent /> */}
      <HomeSwiperModule />
      {/* end of HomeSwiperModule */}

      {/* 02.SERVICE DETAILS - White*/}
      <section className="bg-secondary position-relative pt-lg-3 pt-xl-4 pt-xxl-5">
        <div
          className=" position-absolute start-0 bottom-0 w-100 d-none d-xl-block"
          style={{ backgroundColor: "#971e3a", height: "920px" }}
        ></div>
        <div
          className=" position-absolute start-0 bottom-0 w-100 d-none d-lg-block d-xl-none"
          style={{ backgroundColor: "#971e3a", height: "830px" }}
        ></div>
        <div
          className=" position-absolute start-0 bottom-0 w-100 d-lg-none d-xl-none"
          style={{ backgroundColor: "#971e3a", height: "62%" }}
        ></div>
        <div
          className="bg-faded-dark rounded-circle position-absolute start-50 d-none d-lg-block"
          style={{
            bottom: "220px",
            width: "480px",
            height: "480px",
            marginLeft: "-240px",
          }}
          data-aos="zoom-in"
          data-aos-duration="700"
          data-aos-delay="200"
          data-aos-offset="300"
        ></div>

        <div className="container position-relative zindex-5  pb-2 pb-sm-4 pb-lg-5">
          <AppLinkButtons small={false}/>
         
          {/* <div className="d-flex justify-content-center align-items-center">
            <Link to="/form">
              <div className=" p-3 mt-0 mx-auto z-5">
                <button
                  type="button"
                  className="btn  btn-dark"
                  // data-bs-toggle="modal"
                  // data-bs-target="#getAppLink"
                  // onClick={handleShow}
                >
                  {" "}
                  <i className="ai-edit fs-xl me-2 ms-n1"></i>
                </button>
              </div>
            </Link>
            <Link to="/form">
              <div className=" p-3 mt-0 mx-auto z-5">
                <button
                  type="button"
                  className="btn  btn-dark"
                  // data-bs-toggle="modal"
                  // data-bs-target="#getAppLink"
                  // onClick={handleShow}
                >
                  {" "}
                  <i className="ai-edit fs-xl me-2 ms-n1"></i>Get the app store
                  Link
                </button>
              </div>
            </Link>
            <div className=" p-3 mt-0 mx-auto z-5">
              <button
                type="button"
                className="btn btn-primary mb-5"
                data-bs-toggle="modal"
                data-bs-target="#getAppLink"
              >
                {" "}
                <i className="ai-edit fs-xl me-2 ms-n1"></i>Sign up as early
                users!
              </button>
            </div>
          </div> */}
          <svg
            className="d-block mx-auto text-primary"
            width="511"
            height="40"
            viewBox="0 0 511 40"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M385.252 0.160149C313.41 0.614872 292.869 0.910678 253.008 2.06539C211.7 3.26203 182.137 4.46154 135.231 6.84429C124.358 7.39665 112.714 7.92087 99.0649 8.47247C48.9242 10.4987 39.1671 11.0386 22.9579 12.6833C14.9267 13.4984 7.98117 14.0624 4.08839 14.2162C0.627411 14.3527 0 14.4386 0 14.7762C0 15.0745 5.53537 15.3358 8.56298 15.1804C9.64797 15.1248 12.5875 14.9887 15.0956 14.8782C17.6037 14.7676 23.123 14.4706 27.3608 14.2183C37.3399 13.6238 42.1312 13.4363 59.2817 12.9693C88.1133 12.1844 109.893 11.43 136.647 10.2898C146.506 9.86957 159.597 9.31166 165.737 9.04993C212.308 7.06466 269.195 5.29439 303.956 4.74892C346.139 4.08665 477.094 3.50116 474.882 3.98441C474.006 4.17607 459.021 5.6015 450.037 6.34782C441.786 7.03345 428 8.02235 411.041 9.14508C402.997 9.67773 391.959 10.4149 386.51 10.7832C366.042 12.1673 359.3 12.5966 347.67 13.2569C294.096 16.2987 258.708 18.9493 209.451 23.6091C180.174 26.3788 156.177 29.5584 129.396 34.2165C114.171 36.8648 112.687 37.3352 114.186 39.0402C115.161 40.1495 122.843 40.2933 138.338 39.492C166.655 38.0279 193.265 36.8923 219.043 36.048C235.213 35.5184 237.354 35.4296 253.795 34.6075C259.935 34.3005 270.549 33.8517 277.382 33.6105L289.804 33.1719L273.293 32.999C248.274 32.7369 221.435 32.7528 212.596 33.035C183.334 33.9693 167.417 34.6884 141.419 36.2506C135.222 36.623 129.994 36.8956 129.801 36.8566C127.94 36.4786 169.612 30.768 189.492 28.6769C234.369 23.956 280.582 20.4337 351.602 16.3207C358.088 15.9451 371.108 15.1233 380.535 14.4947C389.962 13.866 404.821 12.8761 413.556 12.2946C447.177 10.057 457.194 9.22358 489.506 5.97543C510.201 3.895 510.311 3.8772 510.875 2.50901C511.496 1.00469 509.838 0.322131 505.088 0.127031C500.576 -0.0584957 416.098 -0.0351424 385.252 0.160149ZM291.144 33.02C291.536 33.0658 292.102 33.0641 292.402 33.0162C292.703 32.9683 292.383 32.9308 291.691 32.9329C290.999 32.935 290.753 32.9743 291.144 33.02Z"></path>
          </svg>

          <div className="row justify-content-center pt-3 pt-sm-4 pt-md-5 mt-sm-1">
            <div
              className="col-8 col-lg-4 order-lg-2"
              style={{ marginTop: "-105px" }}
            >
              {/* <!-- Middle sticky app screen--> */}
              <div
                className="position-sticky top-0 mb-5 pb-sm-2 pb-xl-4"
                style={{ paddingTop: "110px" }}
              >
                <img
                  className="d-block mx-auto"
                  src="./screen2.png"
                  width="322"
                  alt="App screen"
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 order-lg-1 overflow-hidden mt-5 pt-xl-3">
              {/* <!-- Left app sceen--> */}
              <img
                className="d-none d-lg-block rounded-4 ms-auto"
                src="./screen01.png"
                width="297"
                alt="App screen"
                style={{
                  boxShadow: "0 .9375rem 3rem -.5rem rgba(18,34,50, .05)",
                }}
              />
              <div
                className="d-none d-xxl-block"
                style={{ height: "310px" }}
              ></div>
              <div
                className="d-none d-xl-block d-xxl-none"
                style={{ height: "280px" }}
              ></div>
              <div
                className="d-none d-lg-block d-xl-none"
                style={{ height: "200px" }}
              ></div>
              {/* <!-- Left features list--> */}
              <div
                className="mb-5 mx-auto mx-sm-0 pb-lg-2 pb-xl-4"
                style={{ maxWidth: "340px" }}
                // data-aos="fade-right"
                // data-aos-offset="300"
                // data-aos-easing="ease-out-back"
                // data-disable-parallax-down="lg"
              >
                {/* end of service details - white  */}

                {/* 02B.SERVICE DETAILS - PURPLE */}
                <div className="d-table bg-faded-dark rounded-1 p-2 mt-5 mb-3 mb-lg-4">
                  <svg
                    className="d-block m-1 text-warning"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12.0005 7.99608C11.897 7.99608 11.795 7.97508 11.699 7.93308L5.4485 5.18358C5.177 5.06358 5 4.79508 5 4.49658C5 4.19808 5.1755 3.92958 5.4485 3.80958L11.699 1.06007C11.891 0.97607 12.1115 0.97607 12.3035 1.06007L18.554 3.80958C18.8255 3.92958 19.0025 4.19808 19.0025 4.49658C19.0025 4.79508 18.827 5.06358 18.554 5.18358L12.3035 7.93308C12.206 7.97508 12.104 7.99608 12.0005 7.99608Z"></path>
                    <path d="M12.907 9.3054C12.616 9.4329 12.313 9.4959 12.001 9.4959C11.689 9.4959 11.386 9.43291 11.098 9.30691L7 7.50391V8.19241C7 8.80291 7.3195 9.3759 7.831 9.6879C9.2725 10.5624 10.636 10.9989 11.9995 10.9989C13.363 10.9989 14.7265 10.5609 16.168 9.6879C16.681 9.3759 16.999 8.80291 16.999 8.19241V7.50391L12.907 9.3054Z"></path>
                    <path d="M22.2495 4.99909H20.949C20.79 5.87959 20.2095 6.62959 19.38 7.00009H22.0005V19.0001H1.9995V7.00009H4.62C3.7905 6.62959 3.21 5.87959 3.0495 5.00059H1.7505C0.7905 4.99909 0 5.78959 0 6.74959V19.2491C0 20.2091 0.7905 20.9996 1.7505 20.9996H10.0005V22.9991H8.001C7.4505 22.9991 7.0005 23.4491 7.0005 23.9996C7.0005 24.5501 7.4505 25.0001 8.001 25.0001H16.0005C16.551 25.0001 17.001 24.5501 17.001 23.9996C17.001 23.4491 16.551 22.9991 16.0005 22.9991H14.001V20.9996H22.251C23.211 20.9996 24.0015 20.2091 24.0015 19.2491V6.74959C24 5.78959 23.2095 4.99909 22.2495 4.99909Z"></path>
                  </svg>
                </div>
                <h2 className="h4 text-white mb-2 mb-lg-3">
                  Book Your Service
                </h2>
                <p className="text-white opacity-80 mb-0">
                  With our user-friendly platform, you can easily book a service
                  tailored to your specific requirements. Whether it's regular
                  household chores, personal care, medical support, or any other
                  assistance, we've got you covered.
                </p>
              </div>
              <div
                className="mb-5 mx-auto mx-sm-0 pb-lg-2 pb-xl-4"
                style={{ maxWidth: "340px" }}
                // data-aos="fade-right"
                // data-aos-delay="200"
                // data-aos-offset="250"
                // data-aos-easing="ease-out-back"
                // data-disable-parallax-down="lg"
              >
                <div className="d-table bg-faded-dark rounded-1 p-2 mb-3 mb-lg-4">
                  <svg
                    className="d-block m-1 text-warning"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.4995 10.9995C13.916 10.9995 11 13.9155 11 17.499C11 21.0825 13.9145 24 17.4995 24C21.0845 24 23.999 21.084 23.999 17.5005C23.999 13.917 21.083 10.9995 17.4995 10.9995ZM21.803 15H19.9985V13.1955C20.7485 13.632 21.3665 14.2515 21.803 15ZM22.499 17.5005C22.499 17.8425 22.4645 18.177 22.3985 18.501H20V16.5H22.3985C22.4645 16.824 22.499 17.157 22.499 17.5005ZM12.4985 17.5005C12.4985 17.1585 12.533 16.824 12.599 16.5H14.999V18.4995H12.6005C12.5345 18.177 12.4985 17.8425 12.4985 17.5005ZM16.499 16.5H18.4985V18.4995H16.499V16.5ZM18.4985 12.6015V15H16.499V12.6015C16.8215 12.5355 17.156 12.501 17.4995 12.501C17.843 12.501 18.176 12.5355 18.4985 12.6015ZM14.999 13.1955V15H13.1945C13.631 14.2515 14.2505 13.632 14.999 13.1955ZM13.1945 19.9995H14.999V21.804C14.2505 21.3675 13.631 20.7495 13.1945 19.9995ZM16.499 22.3995V20.001H18.4985V22.3995C18.176 22.4655 17.8415 22.5 17.4995 22.5C17.1575 22.5 16.823 22.4655 16.499 22.3995ZM19.9985 21.804V19.9995H21.803C21.3665 20.7495 20.7485 21.3675 19.9985 21.804Z"></path>
                    <path d="M16.0005 4.0005H15V1.0005C15 0.45 14.55 0 13.9995 0H3C2.1795 0 1.4205 0.3405 0.8805 0.8805C0.3405 1.4205 0 2.1705 0 3V19.0005C0 20.6505 1.35 22.0005 3 22.0005H10.29C9.4695 20.7 9 19.1505 9 17.5005C9 12.981 12.54 9.27 16.9995 9.0105V5.001C16.9995 4.4505 16.5495 4.0005 16.0005 4.0005ZM13.0005 4.0005H3C2.4495 4.0005 1.9995 3.5505 1.9995 3C1.9995 2.4495 2.4495 1.9995 3 1.9995H13.0005V4.0005Z"></path>
                  </svg>
                </div>
                <h2 className="h4 text-white mb-2 mb-lg-3">Trusted Partners</h2>
                <p className="text-white opacity-80 mb-0">
                  We have partnered with a network of dedicated individuals and
                  organizations who are ready to serve your loved ones with
                  utmost care and professionalism. Our partners are carefully
                  vetted to ensure the highest quality of service.
                </p>
              </div>

              {/* <div className="d-flex justify-content-center align-items-center">
                <div className=" p-3 mt-0 mx-auto z-5">
                  <button
                    type="button"
                    className="btn  btn-light"
                    data-bs-toggle="modal"
                    data-bs-target="#getAppLink"
                  >
                    {" "}
                    <i className="ai-edit fs-xl me-2 ms-n1"></i> Sign up as
                    early users!
                  </button>
                </div>
              </div> */}
            </div>
            <div className="col-sm-6 col-lg-4 order-lg-3 overflow-hidden mt-lg-4 pt-xl-3">
              {/* <!-- Right app screen--> */}
              <img
                className="d-none d-lg-block rounded-4"
                src="./screen3.jpg"
                width="297"
                alt="App screen"
                style={{
                  boxShadow: "0 .9375rem 3rem -.5rem rgba(18,34,50, .05)",
                }}
              />
              <div
                className="d-none d-xxl-block"
                style={{ height: "520px" }}
              ></div>
              <div
                className="d-none d-xl-block d-xxl-none"
                style={{ height: "490px" }}
              ></div>
              <div
                className="d-none d-lg-block d-xl-none"
                style={{ height: "400px" }}
              ></div>
              {/* <!-- Right features list--> */}
              <div
                className="mb-5 mx-auto me-sm-0 pb-lg-2 pb-xl-4"
                style={{ maxWidth: "340px" }}
                // data-aos="fade-left"
                // data-aos-offset="250"
                // data-aos-delay="400"
                // data-aos-easing="ease-out-back"
                // data-disable-parallax-down="lg"
              >
                <div className="d-table bg-faded-dark rounded-1 p-2 mb-3 mb-lg-4">
                  <svg
                    className="d-block m-1 text-warning"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.9986 0C5.38318 0 0 5.38318 0 11.9986C0 18.6141 5.38041 24 11.9986 24C18.6168 24 23.9972 18.6168 23.9972 12.0014C23.9972 5.38595 18.6141 0 11.9986 0ZM16.7837 17.0052C16.5095 17.3541 16.1025 17.5396 15.6899 17.5396C15.3936 17.5396 15.0945 17.4427 14.8397 17.2489L10.6861 14.0173C10.3482 13.7542 10.1516 13.3499 10.1516 12.9235V6.92281C10.1516 6.15853 10.7719 5.53825 11.5362 5.53825C12.3005 5.53825 12.9207 6.15853 12.9207 6.92281V12.2451L16.54 15.0613C17.1437 15.5292 17.2517 16.4015 16.7837 17.0052Z"></path>
                  </svg>
                </div>
                <h2 className="h4 text-white mb-2 mb-lg-3">Flexible Options</h2>
                <p className="text-white opacity-80 mb-0">
                  We understand that every situation is unique, which is why we
                  offer flexible options to suit your needs. Whether you require
                  hourly assistance, daily support, or a customized schedule,
                  our partners are here to accommodate your preferences.
                </p>
              </div>
              <div
                className="mb-5 mx-auto me-sm-0 pb-lg-2 pb-xl-4"
                style={{ maxWidth: "340px" }}
                // data-aos="fade-left"
                // data-aos-offset="200"
                // data-aos-delay="600"
                // data-aos-easing="ease-out-back"
                // data-disable-parallax-down="lg"
              >
                <div className="d-table bg-faded-dark rounded-1 p-2 mb-3 mb-lg-4">
                  <svg
                    className="d-block m-1 text-warning"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19.9524 20.9857C17.8306 22.8676 15.057 24 12.0132 24C5.38642 24 0 18.6128 0 11.985C0 5.60651 4.98548 0.379975 11.2624 0V5.02678C7.74852 5.39775 5.005 8.38048 5.005 11.985C5.005 15.8493 8.14795 18.9942 12.0132 18.9942C13.6755 18.9942 15.2072 18.413 16.4085 17.4428L19.9524 20.9857Z"></path>
                    <path d="M24.0042 12.7358C23.8436 15.4797 22.7534 17.9818 21.0205 19.9253L17.4766 16.3808C18.298 15.3701 18.8386 14.1085 18.9782 12.7358H24.0042Z"></path>
                    <path d="M23.998 11.234H18.9719C18.6311 7.97046 16.0287 5.36621 12.7656 5.02678V0C18.7917 0.360451 23.6376 5.20701 23.998 11.234Z"></path>
                  </svg>
                </div>
                <h2 className="h4 text-white mb-2 mb-lg-3">
                  Seamless Communication
                </h2>
                <p className="text-white opacity-80 mb-0">
                  Communication is key, and we make it easy for you to stay
                  connected with our partners. Our platform enables you to
                  communicate directly with them, ensuring transparency and
                  peace of mind.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end of service details */}

      {/* 03.SERVICES */}
      <Services />

      {/* 04.HOW DO WE HIRE */}
      <section className="hire container mb-5">
        <h2 className="h1 pb-3 mb-lg-4 mt-4 pt-4">How Do We hire</h2>

        {/* <!-- Create hoverable steps by adding "steps-hoverable" class --> */}
        <div className="steps steps-horizontal-md steps-center steps-hoverable">
          <div className="step active">
            <div className="step-number">
              <div className="step-number-inner">01</div>
            </div>
            <div className="step-body">
              <h5 className="pb-1 mb-2">E-Verification</h5>
              <p className="mb-0">
                Advanced security measures to ensure the authenticity and
                integrity of the verification process.
              </p>
            </div>
          </div>

          <div className="step active">
            <div className="step-number">
              <div className="step-number-inner">02</div>
            </div>
            <div className="step-body">
              <h5 className="pb-1 mb-2">Background Check</h5>
              <p className="mb-0">
                The primary purpose of background checking is to verify the
                candidates histories at various levels.
              </p>
            </div>
          </div>

          <div className="step active">
            <div className="step-number">
              <div className="step-number-inner">03</div>
            </div>
            <div className="step-body">
              <h5 className="pb-1 mb-2">In Person</h5>
              <p className="mb-0">
                It involves face-to-face interactions and physical examinations
                to confirm identity, documents, and other relevant information.
              </p>
            </div>
          </div>
        </div>
        <WorkButton />
      </section>
      {/* end of how do we hire */}

      {/* <!-- FAQ (Accordion)--> */}
      <div className="bg-secondary pt-5 pb-4">
        <div className="container py-md-2 py-lg-3 py-xl-5">
          <div className="row">
            <div className="col-md-4 text-center text-md-start">
              <h2 className="h1">Any questions? Check out the FAQ</h2>
              <p className="pb-3 pb-sm-4">Find Answers to Your Queries</p>
              <div className="d-none d-md-flex justify-content-center">
                <svg
                  className="text-warning ms-n4"
                  width="200"
                  height="211"
                  viewBox="0 0 200 211"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="col-md-8 col-lg-7 offset-lg-1">
              <div className="accordion" id="faq">
                <div className="accordion-item bg-light">
                  <h3 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#questionOne"
                      aria-expanded="true"
                      aria-controls="questionOne"
                    >
                      How do I book a service?
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse show"
                    id="questionOne"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faq"
                  >
                    <div className="accordion-body fs-sm">
                      Booking a service is simple! Just sign up on our website
                      or mobile app, select the desired service, and follow the
                      prompts to schedule the assistance you need.
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-light">
                  <h3 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#questionTwo"
                      aria-expanded="false"
                      aria-controls="questionTwo"
                    >
                      How are the service providers selected?
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="questionTwo"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faq"
                  >
                    <div className="accordion-body fs-sm">
                      We carefully vet and select our service providers based on
                      their qualifications, experience, and reliability,
                      ensuring that your loved ones receive the best care
                      possible.
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-light">
                  <h3 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#questionThree"
                      aria-expanded="false"
                      aria-controls="questionThree"
                    >
                      Can I communicate with the service provider?
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="questionThree"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faq"
                  >
                    <div className="accordion-body fs-sm">
                      Absolutely! Our platform allows you to communicate
                      directly with the service provider assigned to your loved
                      ones, ensuring clear and transparent communication
                      throughout the process.
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-light">
                  <h3 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#questionFour"
                      aria-expanded="false"
                      aria-controls="questionFour"
                    >
                      What safety measures are in place for my loved ones?
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="questionFour"
                    aria-labelledby="headingFour"
                    data-bs-parent="#faq"
                  >
                    <div className="accordion-body fs-sm">
                      We prioritize safety and implement rigorous safety
                      protocols, including background checks for all our service
                      providers, to ensure the well-being and security of your
                      loved ones.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 04a.ABOUT BUTTON */}
          <div className="about-btn d-flex justify-content-center bg-secondary">
            <Link to="/about" className="btn btn-primary mt-4 mt-lg-5">
              Learn More
            </Link>
          </div>
        </div>
      </div>

      {/* <!--05. CTA--> */}
      <div className="bg-secondary">
        <div className="container">
          <div
            style={{ backgroundColor: "#971e3a" }}
            className="position-relative overflow-hidden rounded-5 px-4 px-sm-5 px-lg-0"
          >
            <div className="row align-items-center position-relative zindex-2">
              <div className="col-md-6 col-lg-5 col-xl-4 offset-lg-1 pb-sm-3 pt-5 py-md-0 py-lg-5">
                <h2 className="display-3 text-white text-center text-md-start mb-4 mb-lg-5">
                  Ready to
                  <br className="d-xxl-inline" />
                  Get Started?
                </h2>
                {/* <div className="d-flex justify-content-center align-items-center">
                  <div className=" p-3 mt-0 mx-auto z-5">
                    <button
                      type="button"
                      className="btn btn-light"
                      data-bs-toggle="modal"
                      data-bs-target="#getAppLink"
                    >
                      {" "}
                      <i className="ai-edit fs-xl me-2 ms-n1"></i> Sign up as
                      early users!
                    </button>
                  </div>
                </div> */}
              </div>
              <div className="col-md-6 col-lg-5 offset-xl-1">
                <img
                  className="d-block d-md-none mx-auto"
                  src="./bottom_screen.png"
                  width="420"
                  alt="App screen"
                />
                <div
                  className="d-none d-md-block position-relative mx-auto"
                  style={{ maxWidth: "484px" }}
                >
                  <div className="position-absolute top-0 start-0 w-100 h-100">
                    <svg
                      className="text-warning position-absolute top-0 start-0 w-100 h-100"
                      width="484"
                      height="590"
                      viewBox="0 0 484 590"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M35.0852 77.896C29.0293 78.1976 23.6248 79.9148 17.8537 81.4616C16.7267 81.7638 16.0606 82.9197 16.3605 84.0437C16.6606 85.1684 17.8209 85.8347 18.9481 85.5331C24.4203 84.0662 29.5448 82.3932 35.2968 82.1056C36.4603 82.0476 37.3589 81.0561 37.2973 79.8952C37.2427 78.7329 36.2485 77.8374 35.0852 77.896Z"></path>
                      <path d="M42.4929 62.8378C31.073 56.0023 17.3524 50.4482 4.32343 47.5959C3.18562 47.3476 2.05922 48.0685 1.81258 49.2044C1.56593 50.3402 2.28436 51.4658 3.42217 51.7141C16.0197 54.4726 29.2896 59.844 40.327 66.4552C41.3271 67.052 42.6231 66.7263 43.2192 65.7286C43.8152 64.7309 43.493 63.4346 42.4929 62.8378Z"></path>
                      <path d="M51.1742 57.6399C50.0172 48.6073 44.9377 40.4608 39.1682 33.66C38.4115 32.7739 37.0807 32.6648 36.1979 33.4154C35.3087 34.1687 35.2011 35.4998 35.9511 36.3879C41.2085 42.5807 45.9377 49.949 46.9927 58.1754C47.1402 59.3297 48.1987 60.1459 49.3501 59.9984C50.5016 59.8517 51.3216 58.7935 51.1742 57.6399Z"></path>
                    </svg>
                  </div>
                  <img src="./cta-screens.png" alt="App screens" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of CTA */}
    </>
  );
};

export default Homepage;
