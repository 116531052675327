import React from "react";
import choose from "../Pages/choose.jpg";
import { Link } from "react-router-dom";
import SliderModule from "../components/SliderModule/SliderModule";

const AboutPage = () => {
  return (
    <>
      {/* <Header /> */}
      <div className="">
        <div className="page-wrapper"></div>
        {/* <!-- Page content--> */}
        {/* <!-- Hero--> */}
        <section className="bg-dark position-relative ">
          <div
            className="d-none d-dark-mode-block position-absolute top-0 start-0 w-100 h-100"
            style={{ backgroundColor: "white" }}
          ></div>
          <div className="container dark-mode position-relative zindex-2 py-5 mb-4 mb-sm-5">
            <div className="row pb-5 mb-2 mb-sm-0 mb-lg-3">
              <div className="col-lg-10 col-xl-9">
                {/* <!-- Breadcrumb--> */}
                <nav aria-label="breadcrumb">
                  <ol className="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About us
                    </li>
                  </ol>
                </nav>
                <h1 className="display-2 pb-2 pb-sm-3">
                  Your Trusted Professional Service Provider for Your Loved Ones
                  in India!
                </h1>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- About--> */}
        <SliderModule />
        <section
          className="container position-relative zindex-3"
          style={{ marginTop: "-5px" }}
        >
         
          <div className="row pt-5 mt-n2 mt-sm-0 mt-md-2 mt-lg-5 mt-xl-5">
            <div className="col-lg-12 pt-3">
              <p className="fs-xl">
                At Root, we understand the challenges faced by Indians living
                abroad when it comes to taking care of their parents, relatives,
                and other loved ones back home in India. Distance should never
                be a barrier to ensuring their well-being and comfort. We are
                here to bridge the gap and provide you with a reliable and
                efficient solution.
              </p>
            </div>
            <div className="col-lg-12 pt-3">
              <p className="fs-xl">
                <strong>Our Mission : </strong> To provide a comprehensive range
                of services tailored to the specific needs of the Indian
                diaspora. Whether it's making payments, shopping, fixing a
                leaking faucet, delivering papers or carrying out carpentry
                work, we've got you covered. With a team of skilled and
                experienced handymen, we are committed to delivering top-notch
                services with the utmost professionalism and care.
              </p>
            </div>
          </div>
        </section>

        {/* <!-- How we work (Steps)--> */}
        <section className="pb-5 container pt-5 mt-1 mt-sm-2 mt-xl-4 mt-xxl-5">
          <div className="row align-items-center pt-2 pt-sm-3 pt-md-4 pt-lg-5 mt-xl-2 mt-xxl-3">
            <div className="col-md-6 col-xl-5 pb-3 pb-md-0 mb-4 mb-md-0">
              <div
                className="ratio ratio-1x1 d-flex align-items-center position-relative rounded-circle overflow-hidden bg-size-cover mx-auto"
                style={{ maxWidth: "530px", backgroundImage: `url(${choose})` }}
              >
                <div className="bg-black position-absolute top-0 start-0 w-100 h-100 opacity-50"></div>
                <div className="dark-mode position-relative zindex-2 p-4">
                  <div
                    className="text-center mx-auto"
                    style={{ maxWidth: "275px" }}
                  >
                    <span className="d-block fs-sm text-uppercase mb-3">
                      How we work
                    </span>
                    <h2 className="display-6 mb-0">Why Choose Us</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-5 offset-xl-1">
              <div className="ps-md-4 ps-xl-0">
                <div className="steps steps-hoverable">
                  <div className="step py-3 py-xl-4">
                    <div className="step-number">
                      <div className="step-number-inner">01</div>
                    </div>
                    <div className="step-body">
                      <h3 className="h5 pb-1 mb-2">Trusted Professionals</h3>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        We carefully select and vet our handymen to ensure that
                        they possess the necessary skills, experience, and
                        professionalism required to deliver exceptional service.
                      </p>
                    </div>
                  </div>
                  <div className="step py-3 py-xl-4">
                    <div className="step-number">
                      <div className="step-number-inner">02</div>
                    </div>
                    <div className="step-body">
                      <h3 className="h5 pb-1 mb-2">
                        Convenience at Your Fingertips
                      </h3>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Our user-friendly mobile application allows you to
                        easily request services, track progress, and communicate
                        with our team, all from the comfort of your own home.
                      </p>
                    </div>
                  </div>
                  <div className="step py-3 py-xl-4">
                    <div className="step-number">
                      <div className="step-number-inner">03</div>
                    </div>
                    <div className="step-body">
                      <h3 className="h5 pb-1 mb-2">Timely Service</h3>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        We understand the value of time, and our team is
                        committed to completing tasks promptly and efficiently,
                        ensuring your loved ones' needs are met without delay.
                      </p>
                    </div>
                  </div>
                  <div className="step py-3 py-xl-4">
                    <div className="step-number">
                      <div className="step-number-inner">04</div>
                    </div>
                    <div className="step-body">
                      <h3 className="h5 pb-1 mb-2">Quality Assurance</h3>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        We strive for excellence in every aspect of our service.
                        From the quality of workmanship to customer
                        satisfaction, we maintain a high standard to exceed your
                        expectations.
                      </p>
                    </div>
                  </div>
                  <div className="step py-3 py-xl-4">
                    <div className="step-number">
                      <div className="step-number-inner">05</div>
                    </div>
                    <div className="step-body">
                      <h3 className="h5 pb-1 mb-2">Reliable and Secure</h3>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Your trust and security are our top priorities. We
                        prioritize the safety of your loved ones and ensure that
                        your personal information is handled with the utmost
                        confidentiality.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Services--> */}
        <section className="bg-secondary py-5">
          <div className="container py-3 py-sm-4 py-lg-5 my-md-2 my-lg-3 my-xl-4 my-xxl-5">
            <div className="fs-sm text-uppercase mb-3 mt-xl-2 mt-xxl-3">
              Work with us
            </div>
            <h2 className="h1 pb-3 mb-lg-4">How Do We Do It?</h2>
            <p className="mb-5">
              At Root, we have developed a streamlined and efficient process to
              ensure that your proffesional needs for your loved ones in India
              are met with ease. Here's a glimpse into how we make it happen:
            </p>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 pb-xl-2 pb-xxl-3">
              {/* <!-- Item--> */}
              <div className="col">
                <div className="card h-100 border-0 rounded-5">
                  <div className="card-body pb-3">
                    <h3 className="h4">1. Simple Service Request</h3>
                    <p className="mb-0">
                      Using our user-friendly mobile application, you can easily
                      create a service request in just a few simple steps.
                      Whether it's scheduling a repair, maintenance work, or any
                      other chore, you can provide the necessary details and
                      specifications to help us understand your requirements.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Item--> */}
              <div className="col">
                <div className="card h-100 border-0 rounded-5">
                  <div className="card-body pb-3">
                    <h3 className="h4">2. Service Customization</h3>
                    <p className="mb-0">
                      We understand that every task is unique, and we take pride
                      in offering personalized services. Our dedicated customer
                      support team is available to assist you in customizing
                      your service request, ensuring that it aligns perfectly
                      with your expectations and the specific needs of your
                      loved ones in India.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Item--> */}
              <div className="col">
                <div className="card h-100 border-0 rounded-5">
                  <div className="card-body pb-3">
                    <h3 className="h4">3. Skilled Handymen Selection</h3>
                    <p className="mb-0">
                      Once we receive your service request, our team carefully
                      assesses the requirements and assigns a skilled
                      proffesional who is best suited for the job. We have a
                      network of highly trained professionals with expertise in
                      various proffesional services, ensuring that every task is
                      carried out with precision and care.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Item--> */}
              <div className="col">
                <div className="card h-100 border-0 rounded-5">
                  <div className="card-body pb-3">
                    <h3 className="h4">4. Seamless Coordination</h3>
                    <p className="mb-0">
                      Our mobile application serves as a communication hub
                      between you, our team, and the assigned proffesional. You
                      can easily track the progress of the service, ask
                      questions, and receive updates in real-time, ensuring
                      transparency and efficient coordination throughout the
                      process.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Item--> */}
              <div className="col">
                <div className="card h-100 border-0 rounded-5">
                  <div className="card-body pb-3">
                    <h3 className="h4">5. Quality Service Delivery</h3>
                    <p className="mb-0">
                      We are committed to delivering top-notch service that
                      exceeds your expectations. Our handymen arrive at the
                      designated location fully equipped with the necessary
                      tools and materials to complete the task efficiently. They
                      adhere to strict quality standards and employ best
                      practices to ensure that the service is carried out to the
                      highest level of satisfaction.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Item--> */}
              <div className="col">
                <div className="card h-100 border-0 rounded-5">
                  <div className="card-body pb-3">
                    <h3 className="h4">6. Prompt and Reliable Execution</h3>
                    <p className="mb-0">
                      We understand the importance of timeliness, especially
                      when it comes to your loved ones' well-being in India. Our
                      handymen are punctual and dedicated to completing tasks
                      promptly, minimizing any inconvenience or disruption for
                      your family members.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Item--> */}
              <div className="col">
                <div className="card h-100 border-0 rounded-5">
                  <div className="card-body pb-3">
                    <h3 className="h4">
                      7. Secure and Trustworthy Transactions
                    </h3>
                    <p className="mb-0">
                      We prioritize your trust and safety. Our mobile
                      application ensures secure and encrypted transactions, and
                      we handle your personal information with the utmost
                      confidentiality. You can have peace of mind knowing that
                      your loved ones are in safe hands.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Item--> */}
              <div className="col">
                <div className="card h-100 border-0 rounded-5">
                  <div className="card-body pb-3">
                    <h3 className="h4">8. Customer Satisfaction</h3>
                    <p className="mb-0">
                      Your satisfaction is our ultimate goal. We value your
                      feedback and continuously strive to improve our services
                      based on your input. Our customer support team is
                      available to address any queries or concerns you may have,
                      ensuring that your experience with Root is smooth and
                      hassle-free.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutPage;
