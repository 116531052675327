import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import Slider from "react-slick";
import staticBanner from "../../static-banner.png";
import banner1 from "../../homebanner1.jpg";
import banner2 from "../../homebanner2.jpg";
import banner3 from "../../homebanner3.jpg";

const HomeSwiperModule = () => {
  var settings = {
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [data, setData] = useState([]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://api.root.delivery/user/shop/banner"
  //     );

  //     if (Array.isArray(response.data)) {
  //       sliderItems.push(
  //         ...response.data.map((item, index) => (
  //           <div key={index}>
  //             <img
  //               src={item.bannerImage}
  //               alt={`Image ${index}`}
  //               className="img-fluid"
  //             />
  //           </div>
  //         ))
  //       );
  //     } else {
  //       // Load the static images if the response doesn't contain the 'data' array
  //       sliderItems.push(
  //         ...staticImage.map((image, index) => (
  //           <div key={index}>
  //             <img
  //               src={image}
  //               alt={`Static Image ${index}`}
  //               className="img-fluid"
  //             />
  //           </div>
  //         ))
  //       );
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const staticImage = [banner3];

  // const sliderItems = [];

  // if (data.length > 0) {
  //   sliderItems.push(
  //     ...data.map((photo, index) => (
  //       <div key={index}>
  //         <img src={photo.url} alt={`Photo ${index}`} className="img-fluid" />
  //       </div>
  //     ))
  //   );
  // } else {
  //   sliderItems.push(
  //     ...staticImage.map((image, index) => (
  //       <div key={index}>
  //         <img
  //           src={image}
  //           alt={`Static Image ${index}`}
  //           className="img-fluid"
  //         />
  //       </div>
  //     ))
  //   );
  // }

  const [banner, setBanner] = useState(null);

  // Function to fetch data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.root.delivery/user/shop/banner"
      );

      // Set the banner data to the state
      console.log("sdfbgwkubv", response);
      setBanner(response.data.data.bannerImage);
      console.log("banner", response.data.data.bannerImage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const bannerImage = banner ? `https://api.root.delivery/${banner}` : banner3;

  return (
    <Container fluid>
      {/* <Slider {...settings}>{sliderItems}</Slider> */}
      <img src={bannerImage} alt="image" className="img-fluid" />
    </Container>
  );
};

export default HomeSwiperModule;
