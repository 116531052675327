import React, { useEffect, useState } from "react";
import { AxiosServer } from "..";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "./style.css";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import { Header } from "../components/Header";

const ImgageField = styled.img``;

function capitalizeWords(s) {
  if (!s) {
    return "Null Data";
  } else {
    return s
      .toLowerCase()
      .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
  }
}

const WorkPage = () => {
  const navigate = useNavigate();

  const {
    register,
    unregister,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    control,
    resetField,
    setValue,
  } = useForm({
    defaultValues: {
      partnerName: "",
      phone: "",
      email: "",
      image: "",
      dob: "",
      gender: "",
      partnerType: "",
      runnerLocation: "",
      serviceableLocation: [],
      offeringServices: [],
      accountNumber: "",
      ifscCode: "",
      bankingName: "",
      bankName: "",
      idProof: "",
    },
  });
  const [partnerData, setPartnerData] = useState({
    partnerName: "",
    countryCode: "",
    phone: "",
    email: "",
    image: "",
    dob: "",
    gender: "",
    partnerType: "",
    runnerLocation: "",
    serviceableLocation: [],
    offeringServices: [],
    accountNumber: "",
    ifscCode: "",
    bankingName: "",
    bankName: "",
    idProof: "",
  });

  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setInputValue("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "serviceableLocation") {
      // Split the input value by commas to create an array
      const locations = value.split(",").map((location) => location.trim());
      setPartnerData({ ...partnerData, serviceableLocation: locations });
    } else {
      setPartnerData({ ...partnerData, [name]: value });
    }
  };

  const onSubmit = async (submittedData) => {
    // countryCode: "",
    const countryCode = `+${
      parsePhoneNumber(submittedData.phone)?.countryCallingCode
    }`;
    const phone = parsePhoneNumber(submittedData.phone).nationalNumber;

    const serviceableLocation = JSON.stringify(
      submittedData.serviceableLocation
    );
    const offeringServices = JSON.stringify(submittedData.offeringServices);

    const modifiedData = {
      ...submittedData,
      countryCode,
      phone,
      serviceableLocation,
      offeringServices,
    };

    const PartnerFormData = new FormData();

    Object.entries(modifiedData).forEach(([key, value]) => {
      PartnerFormData.append(key, value);
    });

    try {
      const response = await AxiosServer.post(
        "partner/profile/betaPartner",
        PartnerFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      reset();

      navigate("/submit");
    } catch (error) {
      alert("sorry there was an error in server, try again later");
    }
  };

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const handleChange = (e, label) => {
  //   if (label === "phone") {
  //     const name = "phone";
  //     const value = e;

  //     setPartnerData({ ...partnerData, [name]: value });
  //   } else {
  //     const name = e.target.name;
  //     const value = e.target.value;
  //     setPartnerData({ ...partnerData, [name]: value });
  //   }
  // };
  // const ImageUpload = () => {
  //   const [selectedImage, setSelectedImage] = useState(null);

  //   const handleImageChange = (event) => {
  //     const file = event.target.files[0];
  //     setSelectedImage(file);
  //   };

  const [optionsList, setOptionsList] = useState({
    serviceOptions: [],
    locationOptions: [],
  });

  useEffect(() => {
    (async () => {
      try {
        // services list
        const {
          data: { data: services },
        } = await AxiosServer.get("/user/shop/getServices");

        const data = services === "Service Not Found" ? [] : services;
        const serviceOptions = data
          ?.filter((service) => service?.serviceType === "professional")
          .map((service) => {
            return {
              label: capitalizeWords(service?.serviceName),
              value: service?.id + "",
            };
          });

        // locations list

        const {
          data: { data: locationsList },
        } = await AxiosServer.get("/user/locations");

        const locations =
          locationsList === "Locations Not Found" ? [] : locationsList;
        const locationOptions = locations.map((option) => ({
          label: capitalizeWords(option.name),
          value: option.name.trim()?.toLowerCase(),
        }));

        setOptionsList((pre) => ({ ...pre, serviceOptions, locationOptions }));
        //
      } catch (error) {}
    })();
  }, []);

  return (
    <>
      {/* <Header /> */}
      <div className="container-fluid px-5">
        <div className="row">
          <div className="row">
            <h2
              className="mt-4"
              style={{
                textAlign: "center",
                fontSize: "",
                color: "#000",
                margin: "0px auto",
              }}
            >
              Work With Us
            </h2>
          </div>

          <div className="row justify-content-center my-5">
            <form
              className="col-12"
              style={{ maxWidth: "585px" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* partner name */}
              <div className="row">
                <div className="col-lg-12 pb-3">
                  <label htmlFor="partnerName" className="form-label fs-base">
                    Partner Name:
                  </label>
                  <input
                    className="form-control form-control-lg"
                    placeholder="Enter Your Name"
                    type="text"
                    id="partnerName"
                    {...register("partnerName")}
                    required
                  />
                </div>
                {/* phone */}
                <div className="col-lg-12  pb-3">
                  <label className="form-label fs-base" for="phone">
                    Your Phone Number
                  </label>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, ...otherProps } }) => {
                      return (
                        <PhoneInput
                          phone={false}
                          defaultCountry="US"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          className="form-control form-control-lg input-phone-number"
                          placeholder="Enter your Phone Number"
                          {...otherProps}
                        />
                      );
                    }}
                  />
                </div>

                {/* email */}
                <div className="col-lg-12 pb-3">
                  <label htmlFor="email" className="form-label fs-base">
                    Email:
                  </label>
                  <input
                    placeholder="Enter Your E-mail"
                    className="form-control form-control-lg"
                    type="email"
                    id="email"
                    {...register("email")}
                    required
                  />
                </div>

                {/* password */}
                {/* <div className="col-lg-12 pb-3">
                  <div className="row">
                    <div className="col-12 password-filed">
                      <label
                        htmlFor="password"
                        className="form-label fs-base password-form"
                      >
                        Password:
                      </label>
                      <div className="password-input">
                        <input
                          placeholder="enter your password"
                          className="form-control form-control-lg"
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          value={partnerData.password}
                          onChange={handleInputChange}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="password-btn"
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                        {showPassword ? " Hide Password" : " Show Password"}
                      </button>
                    </div>
                  </div>
                </div> */}

                {/* date of birth */}
                <div className="col-lg-12 pb-3">
                  <div className="col-lg-12  pb-3">
                    <label htmlFor="dob" className="form-label fs-base">
                      Date of Birth:
                    </label>
                    <input
                      placeholder="Enter Your Date of Birth"
                      className="form-control form-control-lg"
                      type="date"
                      id="dob"
                      {...register("dob")}
                      required
                    />
                  </div>

                  {/* gender */}
                  <div className="col-lg-12 pb-3">
                    <label htmlFor="gender" className="form-label fs-base">
                      Gender:
                    </label>
                    <select
                      placeholder="Select Your Gender"
                      className="form-select form-select-lg"
                      id="gender"
                      {...register("gender")}
                      required
                    >
                      <option hidden value="">
                        Select Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  {/* image */}
                  <div className="col-lg-12 pb-3">
                    <label htmlFor="image" className="form-label fs-base">
                      Profile Picture
                    </label>

                    <Controller
                      name="image"
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { value, onChange, ...otherProps },
                      }) => {
                        return (
                          <input
                            placeholder="Upload Your Image"
                            className="form-control form-control-lg"
                            type="file"
                            id="image"
                            onChange={(e) => {
                              onChange(e.target.files[0]);
                            }}
                          />
                        );
                      }}
                    />
                  </div>

                  {/* partner type */}
                  <div className="col-lg-12 pb-3">
                    <label htmlFor="partnerType" className="form-label fs-base">
                      Partner Type
                    </label>
                    <Controller
                      name="partnerType"
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { value, onChange, ...otherProps },
                      }) => {
                        return (
                          <select
                            className="form-select form-select-lg"
                            value={value}
                            onChange={(e) => {
                              unregister("offeringServices");
                              unregister("serviceableLocation");
                              unregister("runnerLocation");
                              onChange(e.target.value);
                            }}
                          >
                            <option hidden value="">
                              Select Partner Type
                            </option>
                            <option value="runner">Runner</option>
                            <option value="professional">Professional</option>
                          </select>
                        );
                      }}
                    />
                  </div>

                  {/* runner location */}
                  {watch("partnerType") === "runner" && (
                    <div className="col-lg-12 pb-3">
                      <label
                        htmlFor="runnerLocation"
                        className="form-label fs-base"
                      >
                        Runner Location:
                      </label>

                      <select
                        className="form-select form-select-lg"
                        {...register("runnerLocation")}
                        required
                      >
                        <option hidden value="">
                          Select Runner Location
                        </option>
                        {optionsList.locationOptions.map((location, index) => {
                          return (
                            <option key={index} value={location.value}>
                              {location.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}

                  {watch("partnerType") === "professional" && (
                    <>
                      {/* offering services */}
                      <div className="col-lg-12 pb-3">
                        <label
                          htmlFor="offeringServices"
                          className="form-label fs-base"
                        >
                          Offering Services:
                        </label>

                        {/*  */}
                        <Controller
                          name="offeringServices"
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { value, onChange, ...otherProps },
                          }) => {
                            return (
                              <Select
                                isMulti={true}
                                defaultValue={value}
                                onChange={onChange}
                                options={optionsList.serviceOptions}
                              />
                            );
                          }}
                        />
                      </div>
                      {/* serviceable locations */}
                      <div className="col-lg-12 pb-3">
                        <label
                          htmlFor="serviceableLocation"
                          className="form-label fs-base"
                        >
                          Serviceable Location:
                        </label>

                        {/*  */}
                        <Controller
                          name="serviceableLocation"
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { value, onChange, ...otherProps },
                          }) => {
                            return (
                              <Select
                                isMulti={true}
                                defaultValue={value}
                                onChange={onChange}
                                options={optionsList.locationOptions}
                              />
                            );
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* account number */}
                  <div className="col-lg-12 pb-3">
                    <label
                      htmlFor="accountNumber"
                      className="form-label fs-base"
                    >
                      Account Number
                    </label>
                    <input
                      placeholder="Enter your Account Number"
                      className="form-control form-control-lg"
                      type="text"
                      id="accountNumber"
                      {...register("accountNumber")}
                      required
                    />
                  </div>

                  {/* ifsc code */}
                  <div className="col-lg-12 pb-3">
                    <label htmlFor="ifscCode" className="form-label fs-base">
                      IFSC Code
                    </label>
                    <input
                      placeholder="Enter Your IFSC Code"
                      className="form-control form-control-lg"
                      type="text"
                      id="ifscCode"
                      {...register("ifscCode")}
                      required
                    />
                  </div>

                  {/* banking code */}
                  <div className="col-lg-12 pb-3">
                    <label htmlFor="bankingName" className="form-label fs-base">
                      Banking Name
                    </label>
                    <input
                      placeholder="Enter Your Banking Name"
                      className="form-control form-control-lg"
                      type="text"
                      id="bankingName"
                      {...register("bankingName")}
                      required
                    />
                  </div>

                  {/* bank name */}
                  <div className="col-lg-12 pb-3">
                    <label htmlFor="bankName" className="form-label fs-base">
                      Bank Name
                    </label>
                    <input
                      placeholder="Enter your Bank Name"
                      className="form-control form-control-lg"
                      type="text"
                      id="bankName"
                      {...register("bankName")}
                      required
                    />
                  </div>

                  {/* iId proof */}
                  <div className="col-lg-12 pb-3">
                    <label htmlFor="idProof" className="form-label fs-base">
                      KYC Document (any)
                    </label>

                    <Controller
                      name="idProof"
                      control={control}
                      rules={{ required: true }}
                      render={({
                        field: { value, onChange, ...otherProps },
                      }) => {
                        return (
                          <input
                            className="form-control form-control-lg"
                            type="file"
                            id="idProof"
                            onChange={(e) => {
                              onChange(e.target.files[0]);
                            }}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="pt-3" style={{ textAlign: "center" }}>
                    <button className="btn btn-lg btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkPage;
