import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import Homepage from "./Pages/Homepage";
import FormPage from "./Pages/FormPage";
import SubmitPage from "./Pages/SubmitPage";
import AboutPage from "./Pages/AboutPage";
import "react-phone-number-input/style.css";
import ScrollToTop from "./components/ScrollToTop";
import WorkPage from "./Pages/WorkPage";
import axios from "axios";
import TermsOfConditions from "./Pages/TermsOfConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import NotFound from "./Pages/NotFound";
import Layout from "./Pages/Layout";
// import PrivacyPolicy from "./Pages/PrivacyPolicy";

const root = createRoot(document.getElementById("root"));

export const SERVER_URL = "https://api.root.delivery/";
// export const SERVER_URL = "http://192.168.29.172:3000/";
export const AxiosServer = axios.create({
  baseURL: SERVER_URL,
});

root.render(
  <React.Fragment>
    <BrowserRouter>
      <ScrollToTop />
      {/* <div className="page-wrapper"> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="form" element={<FormPage />} />
          <Route path="submit" element={<SubmitPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="work" element={<WorkPage />} />
          <Route path="terms" element={<TermsOfConditions />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* </div> */}
    </BrowserRouter>
  </React.Fragment>
);
