import React, { useState, useEffect } from "react";
import axios from "axios";
import "./assets/styles/style.css";
import { Link } from "react-router-dom";
// import FormModule from "../../Pages/FormPage";
import WorkPage from "../../Pages/WorkPage";

export const WorkButton = () => {
  const [country, setCountry] = useState("");

  useEffect(() => {
    fetchLocation();
  }, []);

  const fetchLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      console.log(response);
      const { country } = response.data;
      setCountry(country);
    } catch (error) {
      console.log("Error fetching location:", error);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-5">
      <div>
        {country === "IN" && (
          <Link to="/work" element={<WorkPage />}>
            <button className="btn btn-primary">Work With Us</button>
          </Link>
        )}
      </div>
    </div>
  );
};
