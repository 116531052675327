import { Link } from "react-router-dom";
import submit from "./submit.png";

const Submit = () => {
  return (
    <>
    
      <div className="container-fluid p-5">
        <div className="row pt-4">
          {/*  */}

          <div className="row" style={{ margin: "40px auto" }}>
            <div className="d-flex justify-content-center">
              <img
                alt=""
                src={submit}
                className="img-fluid w-50"
                style={{ display: "inline-block", maxWidth: "490px" }}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <h3 className="pb-3">
                Thanks for your Submission. We will reach you Shortly..!
              </h3>
              <Link to="/" className="btn btn-dark btn-lg">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Submit;
