import React from "react";
import { Col, Row } from "react-bootstrap";
import app_store from "../../assets/app_store.svg";
import play_store from "../../assets/play_store.svg";

const AppLinkButtons = ({ small }) => {
  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.root_delivery.customer&pcampaignid=web_share";
  const appStoreLink = "https://apps.apple.com/us/app/rootrs/id6450861618";

  return (
    <div>
      <Row className="mb-3">
        <Col
          lg={6}
          md={12}
          xs={12}
          className="d-flex justify-content-lg-end justify-content-center  align-items-center"
        >
          <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
            <img
              src={play_store}
              alt="Play Store"
              className="img-fluid mt-4"
              style={{
                width: small ? "150px" : "100%", // Adjusted syntax here
              }}
            />
          </a>
        </Col>
        <Col
          lg={6}
          md={12}
          xs={12}
          className="d-flex justify-content-lg-start justify-content-center align-items-center"
        >
          <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
            <img
              src={app_store}
              alt="app_store"
              className="img-fluid mt-4"
              style={{
                width: small ? "150px" : "100%",
              }}
            />
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default AppLinkButtons;
