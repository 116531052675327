import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const FormModule = ({ modalOpenState = false }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    phone: "",
    countryCode: "",
    email: "",
    servicingFor: "",
    servicingLocation: "",
    deviceId: "",
  });

  React.useEffect(() => {
    return () => {
      setValues(() => ({
        name: "",
        phone: "",
        countryCode: "",
        email: "",
        servicingFor: "",
        servicingLocation: "",
        deviceId: "",
      }));
    };
  }, [modalOpenState]);
  console.log("valuesssss", console.table(values));
  const handleChange = (e, label) => {
    // console.log("hello");
    if (label === "phone") {
      const countryCode =
        typeof e === "string" && `+${parsePhoneNumber(e)?.countryCallingCode}`;
      const phone = e;

      setValues({ ...values, phone, countryCode });
    } else {
      const name = e.target.name;
      const value = e.target.value;
      setValues({ ...values, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // await axios.post("http://192.168.29.80:3000/user/betauser", {
      await axios.post("https://api.root.delivery/user/betauser", {
        ...values,
        phone: parsePhoneNumber(values.phone).nationalNumber,
      });
      alert("Thank you for your submission");
    } catch (error) {
      console.error(error);
      alert("Sorry, there was an error in submitting. Please try again later.");
    }
  };

  return (
    <div className="container-fluid px-5">
      <div className="row">
        <div className="row">
          <h2
            className="mb-4 bg-img"
            style={{
              textAlign: "center",
              fontSize: "45px",
              color: "#fff",

              textShadow: "1px 1px 1px #971e3a",
              margin: "0px auto",
            }}
          >
            Enter your Info
          </h2>
        </div>

        <div className="row justify-content-center my-5">
          <form
            className="col-12"
            style={{ maxWidth: "585px" }}
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-12  pb-3">
                <label className="form-label fs-base" for="phone">
                  Your Name
                </label>
                <input
                  className="form-control form-control-lg"
                  name="name"
                  type="text"
                  placeholder="Enter your Name"
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-12  pb-3">
                <label className="form-label fs-base" for="phone">
                  Your Phone Number
                </label>
                <PhoneInput
                  // international={false}
                  defaultCountry="US"
                  value={values.phone}
                  required={true}
                  onChange={(e) => {
                    handleChange(e, "phone");
                  }}
                  className="form-control form-control-lg input-phone-number"
                  placeholder="Enter your Phone number"
                />
              </div>
              <div className="col-lg-12  pb-3">
                <label className="form-label fs-base" for="email">
                  Your Email
                </label>
                <input
                  className="form-control form-control-lg"
                  name="email"
                  type="text"
                  placeholder="Enter your Email"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12  pb-3">
                <label className="form-label fs-base" for="location">
                  Your Location
                </label>
                <input
                  className="form-control form-control-lg"
                  name="location"
                  type="text"
                  placeholder="Enter your Location"
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-12 pb-3">
                <label className="form-label fs-base" for="location">
                  Servicing for
                </label>
                <select
                  className="form-select form-select-lg"
                  id="location"
                  name="servicingFor"
                  required
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Select Servicing for
                  </option>
                  <option value="self" style={{ cursor: "pointer" }}>
                    Self
                  </option>
                  <option value="family">Family</option>
                  <option value="friends">Friends</option>
                </select>
              </div>

              <div className="col-lg-12 pb-3">
                <label className="form-label fs-base" for="location">
                  Servicing Location
                </label>
                <select
                  className="form-select form-select-lg"
                  id="location"
                  name="servicingLocation"
                  required
                  style={{ textTransform: "capitalize" }}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Select Servicing Location
                  </option>{" "}
                  <option value="ahmedabad">Ahmedabad</option>
                  <option value="bangalore">Bangalore</option>
                  <option value="bhavnagar">Bhavnagar</option>
                  <option value="bhopal">Bhopal</option>
                  <option value="bhuvaneshwar">Bhuvaneshwar</option>
                  <option value="bokoroSteelCity">bokoro Steel City</option>
                  <option value="chandigarh">chandigarh</option>
                  <option value="chennai">Chennai</option>
                  <option value="coimbatore">coimbatore</option>
                  <option value="dehradun">dehradun</option>
                  <option value="delhi">Delhi</option>
                  <option value="erode">Erode</option>
                  <option value="guntur">guntur</option>
                  <option value="gurugram">gurugram</option>
                  <option value="hyderabad">Hyderabad</option>
                  <option value="indore">indore</option>
                  <option value="jamnagar">jamnagar</option>
                  <option value="jodhpur">jodhpur</option>
                  <option value="kakinada">kakinada</option>
                  <option value="kochi">kochi</option>
                  <option value="kolkata">Kolkata</option>
                  <option value="kurnool">kurnool</option>
                  <option value="madurai">madurai</option>
                  <option value="mumbai">Mumbai</option>
                  <option value="nellore">nellore</option>
                  <option value="patna">patna</option>
                  <option value="rajamahendravaram">rajamahendravaram</option>
                  <option value="rajkot">rajkot</option>
                  <option value="rourkela">rourkela</option>
                  <option value="salem">salem</option>
                  <option value="surat">surat</option>
                  <option value="thanjavur">thanjavur</option>
                  <option value="thiruchirappalli">thiruchirappalli</option>
                  <option value="thirunelveli">thirunelveli</option>
                  <option value="tiruppur">tiruppur</option>
                  <option value="tiruvannamalai">tiruvannamalai</option>
                  <option value="tiruvendrum">tiruvendrum</option>
                  <option value="udhaipur">udhaipur</option>
                  <option value="vadodara">vadodara</option>
                  <option value="vellore">vellore</option>
                  <option value="vijayawada">vijayawada</option>
                  <option value="visakhapatnam">visakhapatnam</option>
                  <option value="warangal">warangal</option>
                </select>
              </div>
            </div>
            <div className="col-lg-12 pb-3">
              <label className="form-label fs-base" for="location">
                Choose Your Device
              </label>

              <select
                className="form-select form-select-lg"
                id="deviceId"
                name="deviceId"
                required
                onChange={handleChange}
              >
                // <option hidden label="Choose Your Device" value=""></option>
                <option value="android">Android</option>
                <option value="ios">IOS</option>
              </select>
            </div>
            <div className="pt-3" style={{ textAlign: "center" }}>
              <button className="btn btn-lg btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormModule;

// import React, { useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import "react-phone-number-input/style.css";
// import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

// const FormModule = () => {
//   const navigate = useNavigate();
//   const [values, setValues] = useState({
//     name: "",
//     phoneNumber: "",
//     countryCode: "",
//     email: "",
//     servicingFor: "",
//     servicingLocation: "",
//     // deviceId: "",
//   });

//   const handleChange = (e, label) => {
//     if (label === "phone") {
//       const countryCode =
//         typeof e === "string" && `+${parsePhoneNumber(e)?.countryCallingCode}`;
//       // console.log(parsePhoneNumber(e).nationalNumber);
//       const phoneNumber = e;

//       setValues({ ...values, phoneNumber, countryCode });
//     } else {
//       const name = e.target.name;
//       const value = e.target.value;
//       setValues({ ...values, [name]: value });
//     }
//   };

//   console.log(values);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       const response = await axios.post(
//         // "https://api.root.delivery/user/betauser",
//         "http://192.168.29.80:3000/user/betauser",
//         {
//           ...values,
//           phoneNumber: parsePhoneNumber(values.phoneNumber).nationalNumber,
//         }
//       );
//       console.log(response.data);
//       alert("Thank you for your submission");
//     } catch (error) {
//       console.error(error);
//       alert("Sorry, there was an error in submitting. Please try again later.");
//     }
//   };

//   return (
//     <>
//       {/* <Header /> */}
//       <div className="container-fluid px-5">
//         <div className="row">
//           <div className="row">
//             <h2
//               className="mb-4 bg-img"
//               style={{
//                 textAlign: "center",
//                 fontSize: "45px",
//                 color: "#fff",

//                 textShadow: "1px 1px 1px #971e3a",
//                 margin: "0px auto",
//               }}
//             >
//               Enter your Info
//             </h2>
//           </div>

//           <div className="row justify-content-center my-5">
//             <form
//               className="col-12"
//               style={{ maxWidth: "585px" }}
//               onSubmit={handleSubmit}
//             >
//               <div className="row">
//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="phone">
//                     Your Name
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="name"
//                     type="text"
//                     placeholder="Enter your Name"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="phone">
//                     Your Phone Number
//                   </label>
//                   <PhoneInput
//                     international={false}
//                     defaultCountry="IN"
//                     value={values.phoneNumber}
//                     onChange={(e) => {
//                       handleChange(e, "phone");
//                     }}
//                     className="form-control form-control-lg input-phone-number"
//                     placeholder="Enter your Phone number"
//                   />
//                 </div>
//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="email">
//                     Your Email
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="email"
//                     type="text"
//                     placeholder="Enter your Email"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="location">
//                     Your Location
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="location"
//                     type="text"
//                     placeholder="Enter your Location"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <div className="col-lg-12 pb-3">
//                   <label className="form-label fs-base" for="location">
//                     Servicing for
//                   </label>
//                   <select
//                     className="form-select form-select-lg"
//                     id="location"
//                     name="servicingFor"
//                     required
//                     onChange={handleChange}
//                   >
//                     <option value="" disabled selected>
//                       Select Servicing for
//                     </option>
//                     <option value="self" style={{ cursor: "pointer" }}>
//                       Self
//                     </option>
//                     <option value="family">Family</option>
//                     <option value="friends">Friends</option>
//                   </select>
//                 </div>

//                 <div className="col-lg-12 pb-3">
//                   <label className="form-label fs-base" for="location">
//                     Servicing Location
//                   </label>
//                   <select
//                     className="form-select form-select-lg"
//                     id="location"
//                     name="servicingLocation"
//                     required
//                     style={{ textTransform: "capitalize" }}
//                     onChange={handleChange}
//                   >
//                     <option value="" disabled selected>
//                       Select Servicing Location
//                     </option>{" "}
//                     <option value="ahmedabad">Ahmedabad</option>
//                     <option value="bangalore">Bangalore</option>
//                     <option value="bhavnagar">Bhavnagar</option>
//                     <option value="bhopal">Bhopal</option>
//                     <option value="bhuvaneshwar">Bhuvaneshwar</option>
//                     <option value="bokoroSteelCity">bokoro Steel City</option>
//                     <option value="chandigarh">chandigarh</option>
//                     <option value="chennai">Chennai</option>
//                     <option value="coimbatore">coimbatore</option>
//                     <option value="dehradun">dehradun</option>
//                     <option value="delhi">Delhi</option>
//                     <option value="erode">Erode</option>
//                     <option value="guntur">guntur</option>
//                     <option value="gurugram">gurugram</option>
//                     <option value="hyderabad">Hyderabad</option>
//                     <option value="indore">indore</option>
//                     <option value="jamnagar">jamnagar</option>
//                     <option value="jodhpur">jodhpur</option>
//                     <option value="kakinada">kakinada</option>
//                     <option value="kochi">kochi</option>
//                     <option value="kolkata">Kolkata</option>
//                     <option value="kurnool">kurnool</option>
//                     <option value="madurai">madurai</option>
//                     <option value="mumbai">Mumbai</option>
//                     <option value="nellore">nellore</option>
//                     <option value="patna">patna</option>
//                     <option value="rajamahendravaram">rajamahendravaram</option>
//                     <option value="rajkot">rajkot</option>
//                     <option value="rourkela">rourkela</option>
//                     <option value="salem">salem</option>
//                     <option value="surat">surat</option>
//                     <option value="thanjavur">thanjavur</option>
//                     <option value="thiruchirappalli">thiruchirappalli</option>
//                     <option value="thirunelveli">thirunelveli</option>
//                     <option value="tiruppur">tiruppur</option>
//                     <option value="tiruvannamalai">tiruvannamalai</option>
//                     <option value="tiruvendrum">tiruvendrum</option>
//                     <option value="udhaipur">udhaipur</option>
//                     <option value="vadodara">vadodara</option>
//                     <option value="vellore">vellore</option>
//                     <option value="vijayawada">vijayawada</option>
//                     <option value="visakhapatnam">visakhapatnam</option>
//                     <option value="warangal">warangal</option>
//                   </select>
//                 </div>
//               </div>
//               {/* <div className="col-lg-12 pb-3">
//                 <label className="form-label fs-base" for="location">
//                   Choose Your Device
//                 </label>

//                 <select
//                   className="form-select form-select-lg"
//                   id="deviceId"
//                   name="deviceId"
//                   required
//                   onChange={handleChange}
//                 >
//                   //{" "}
//                   <option hidden label="Choose Your Device" value=""></option>
//                   <option value="android">Android</option>
//                   <option value="ios">IOS</option>
//                 </select>
//               </div> */}
//               <div className="pt-3" style={{ textAlign: "center" }}>
//                 <button className="btn btn-lg btn-primary" type="submit">
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FormModule;

// import React, { useState } from "react";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// // import Phone from "../components/Phone/Phone";
// // import Country from "../components/country";
// // import Countryautofill from "../components/country";
// // import StepperModule1 from "./steppermodule";
// // import Buttonmodule from "../components/Buttonmodule";

// const FormModule = () => {
//   const navigate = useNavigate();
//   const [values, setValues] = useState({
//     phone: "",
//     name: "",
//     email: "",
//     location: "",
//     servicingFor: "",
//     servicingLocation: "",
//   });

//   const handleChange = (e) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     setValues({ ...values, [name]: value });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     //
//     const url = "https://api.root.delivery";

//     axios
//       .post(`${url}/user/betaUser`, values)
//       .then((res) => {
//         navigate("/submit");
//       })
//       .catch((err) => {
//         console.log(err);
//         alert("Sorry Error in Submitting, try later");
//       });
//   };

//   return (
//     <>
//       <div className="container-fluid px-5">
//         <div className="row">
//           {/*  */}

//           <div className="row">
//             <h2
//               className="mb-4 bg-img"
//               style={{
//                 textAlign: "center",
//                 fontSize: "45px",
//                 color: "#fff",

//                 textShadow: "1px 1px 1px #971e3a",
//               }}
//             >
//               Enter your Info
//             </h2>
//           </div>

//           {/* form */}
//           <div className="row justify-content-center my-5">
//             <form
//               className="col-12"
//               style={{ maxWidth: "585px" }}
//               onSubmit={handleSubmit}
//             >
//               {/* form block */}
//               <div className="row">
//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="phone">
//                     Your Name
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="name"
//                     type="text"
//                     placeholder="Enter your Name"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="phone">
//                     Your E-mail
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="email"
//                     type="email"
//                     placeholder="Enter your e-mail"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="phone">
//                     Your Phone Number
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="phone"
//                     type="text"
//                     placeholder="Phone number with country code"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="location">
//                     Your Location
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="location"
//                     type="text"
//                     placeholder="Enter your Location"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <div className="col-lg-12 pb-3">
//                   <label className="form-label fs-base" for="location">
//                     Servicing for
//                   </label>
//                   <select
//                     className="form-select form-select-lg"
//                     id="location"
//                     name="servicingFor"
//                     required
//                     onChange={handleChange}

//                   >
//                     <option value="" disabled selected >
//                       Select Servicing for

//                     </option>
//                     <option value="self" style={{ cursor: "pointer" }}>Self</option>
//                     <option value="family">Family</option>
//                     <option value="friends">Friends</option>
//                   </select>
//                 </div>

//                 <div className="col-lg-12 pb-3">
//                   <label className="form-label fs-base" for="location">
//                     Servicing Location
//                   </label>
//                   <select
//                     className="form-select form-select-lg"
//                     id="location"
//                     name="servicingLocation"
//                     required
//                     style={{ textTransform: "capitalize" }}
//                     onChange={handleChange}
//                   >
//                     <option value="" disabled selected>
//                       Select Servicing Location
//                     </option>{" "}
//                     <option value="ahmedabad">Ahmedabad</option>
//                     <option value="bangalore">Bangalore</option>
//                     <option value="bhavnagar">Bhavnagar</option>
//                     <option value="bhopal">Bhopal</option>
//                     <option value="bhuvaneshwar">Bhuvaneshwar</option>
//                     <option value="bokoroSteelCity">bokoro Steel City</option>
//                     <option value="chandigarh">chandigarh</option>
//                     <option value="chennai">Chennai</option>
//                     <option value="coimbatore">coimbatore</option>
//                     <option value="dehradun">dehradun</option>
//                     <option value="delhi">Delhi</option>
//                     <option value="erode">Erode</option>
//                     <option value="guntur">guntur</option>
//                     <option value="gurugram">gurugram</option>
//                     <option value="hyderabad">Hyderabad</option>
//                     <option value="indore">indore</option>
//                     <option value="jamnagar">jamnagar</option>
//                     <option value="jodhpur">jodhpur</option>
//                     <option value="kakinada">kakinada</option>
//                     <option value="kochi">kochi</option>
//                     <option value="kolkata">Kolkata</option>
//                     <option value="kurnool">kurnool</option>
//                     <option value="madurai">madurai</option>
//                     <option value="mumbai">Mumbai</option>
//                     <option value="nellore">nellore</option>
//                     <option value="patna">patna</option>
//                     <option value="rajamahendravaram">rajamahendravaram</option>
//                     <option value="rajkot">rajkot</option>
//                     <option value="rourkela">rourkela</option>
//                     <option value="salem">salem</option>
//                     <option value="surat">surat</option>
//                     <option value="thanjavur">thanjavur</option>
//                     <option value="thiruchirappalli">thiruchirappalli</option>
//                     <option value="thirunelveli">thirunelveli</option>
//                     <option value="tiruppur">tiruppur</option>
//                     <option value="tiruvannamalai">tiruvannamalai</option>
//                     <option value="tiruvendrum">tiruvendrum</option>
//                     <option value="udhaipur">udhaipur</option>
//                     <option value="vadodara">vadodara</option>
//                     <option value="vellore">vellore</option>
//                     <option value="vijayawada">vijayawada</option>
//                     <option value="visakhapatnam">visakhapatnam</option>
//                     <option value="warangal">warangal</option>
//                   </select>
//                 </div>
//               </div>
//               <div className="pt-3" style={{ textAlign: "center" }}>
//                 <button className="btn btn-lg btn-primary" type="submit">
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FormModule;

// need back end changes
// import React, { useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import "react-phone-number-input/style.css";
// import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

// const FormModule = () => {
//   const navigate = useNavigate();
//   const [values, setValues] = useState({
//     name: "",
//     contact: "",
//     countryCode: "",

//     email: "",
//     location: "",
//     servicingFor: "",
//     servicingLocation: "",
//   });

//   const handleChange = (e, label) => {
//     if (label === "phone") {
//       const countryCode =
//         typeof e === "string" && `+${parsePhoneNumber(e)?.countryCallingCode}`;
//       // console.log(parsePhoneNumber(e).nationalNumber);
//       const contact = e;

//       setValues({ ...values, contact, countryCode });
//     } else {
//       const name = e.target.name;
//       const value = e.target.value;
//       setValues({ ...values, [name]: value });
//     }
//   };

//   console.log(values);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       const response = await axios.post(
//         "https://api.root.delivery/user/userSms",
//         {
//           ...values,
//           contact: parsePhoneNumber(values.contact).nationalNumber,
//         }
//       );
//       console.log(response.data);
//       alert("Thank you for your submission");
//     } catch (error) {
//       console.error(error);
//       alert("Sorry, there was an error in submitting. Please try again later.");
//     }
//   };

//   return (
//     <>
//       {/* <Header /> */}
//       <div className="container-fluid px-5">
//         <div className="row">
//           <div className="row">
//             <h2
//               className="mb-4 bg-img"
//               style={{
//                 textAlign: "center",
//                 fontSize: "45px",
//                 color: "#fff",

//                 textShadow: "1px 1px 1px #971e3a",
//                 margin: "0px auto",
//               }}
//             >
//               Enter your Info
//             </h2>
//           </div>

//           <div className="row justify-content-center my-5">
//             <form
//               className="col-12"
//               style={{ maxWidth: "585px" }}
//               onSubmit={handleSubmit}
//             >
//               <div className="row">
//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="phone">
//                     Your Name
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="name"
//                     type="text"
//                     placeholder="Enter your Name"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="phone">
//                     Your Phone Number
//                   </label>
//                   <PhoneInput
//                     international={false}
//                     defaultCountry="IN"
//                     value={values.contact}
//                     onChange={(e) => {
//                       handleChange(e, "phone");
//                     }}
//                     className="form-control form-control-lg input-phone-number"
//                     placeholder="Enter your Phone number"
//                   />
//                 </div>

//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="phone">
//                     Your E-mail
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="email"
//                     type="email"
//                     placeholder="Enter your e-mail"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <div className="col-lg-12  pb-3">
//                   <label className="form-label fs-base" for="location">
//                     Your Location
//                   </label>
//                   <input
//                     className="form-control form-control-lg"
//                     name="location"
//                     type="text"
//                     placeholder="Enter your Location"
//                     required
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <div className="col-lg-12 pb-3">
//                   <label className="form-label fs-base" for="location">
//                     Servicing for
//                   </label>
//                   <select
//                     className="form-select form-select-lg"
//                     id="location"
//                     name="servicingFor"
//                     required
//                     onChange={handleChange}
//                   >
//                     <option
//                       hidden
//                       label="Select Servicing for"
//                       value=""
//                     ></option>
//                     <option value="self">Self</option>
//                     <option value="family">Family</option>
//                     <option value="friends">Friends</option>
//                   </select>
//                 </div>

//                 <div className="col-lg-12 ">
//                   <label className="form-label fs-base" for="location">
//                     Servicing Location
//                   </label>
//                   <select
//                     className="form-select form-select-lg"
//                     id="location"
//                     name="servicingLocation"
//                     required
//                     style={{ textTransform: "capitalize" }}
//                     onChange={handleChange}
//                   >
//                     <option
//                       hidden
//                       label="Select Servicing Location"
//                       value=""
//                     ></option>
//                     <option value="ahmedabad">Ahmedabad</option>
//                     <option value="bangalore">Bangalore</option>
//                     <option value="bhavnagar">Bhavnagar</option>
//                     <option value="bhopal">Bhopal</option>
//                     <option value="bhuvaneshwar">Bhuvaneshwar</option>
//                     <option value="bokoroSteelCity">bokoro Steel City</option>
//                     <option value="chandigarh">chandigarh</option>
//                     <option value="chennai">Chennai</option>
//                     <option value="coimbatore">coimbatore</option>
//                     <option value="dehradun">dehradun</option>
//                     <option value="delhi">Delhi</option>
//                     <option value="erode">Erode</option>
//                     <option value="guntur">guntur</option>
//                     <option value="gurugram">gurugram</option>
//                     <option value="hyderabad">Hyderabad</option>
//                     <option value="indore">indore</option>
//                     <option value="jamnagar">jamnagar</option>
//                     <option value="jodhpur">jodhpur</option>
//                     <option value="kakinada">kakinada</option>
//                     <option value="kochi">kochi</option>
//                     <option value="kolkata">Kolkata</option>
//                     <option value="kurnool">kurnool</option>
//                     <option value="madurai">madurai</option>
//                     <option value="mumbai">Mumbai</option>
//                     <option value="nellore">nellore</option>
//                     <option value="patna">patna</option>
//                     <option value="rajamahendravaram">rajamahendravaram</option>
//                     <option value="rajkot">rajkot</option>
//                     <option value="rourkela">rourkela</option>
//                     <option value="salem">salem</option>
//                     <option value="surat">surat</option>
//                     <option value="thanjavur">thanjavur</option>
//                     <option value="thiruchirappalli">thiruchirappalli</option>
//                     <option value="thirunelveli">thirunelveli</option>
//                     <option value="tiruppur">tiruppur</option>
//                     <option value="tiruvannamalai">tiruvannamalai</option>
//                     <option value="tiruvendrum">tiruvendrum</option>
//                     <option value="udhaipur">udhaipur</option>
//                     <option value="vadodara">vadodara</option>
//                     <option value="vellore">vellore</option>
//                     <option value="vijayawada">vijayawada</option>
//                     <option value="visakhapatnam">visakhapatnam</option>
//                     <option value="warangal">warangal</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="pt-3" style={{ textAlign: "center" }}>
//                 <button className="btn btn-lg btn-primary" type="submit">
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FormModule;
