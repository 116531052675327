import React from "react";

const TermsOfConditions = () => {
  return (
    <div className="container">
      <h5 className="text-center">Terms and Conditions of Service</h5>
      <h5> General</h5>
      <p>
        RootRS INC (hereinafter referred to as RootRS INC) is pleased to make
        its services, as described below, available on the following terms and
        conditions.
      </p>
      <p>
        Terms and Conditions of Service are a legal contract between the
        individual using the service (“you”, “Yours”, “Yourself”) and RootRS INC
        (“Company”, “we”, “us”, “our”, or “ours”). “User” shall mean and refer
        to the person(s) making bookings (from within the United States of
        America) for Services/Products, to be availed by end users in India.
      </p>
      <p>
        Terms and Conditions of Service referenced here are for using this
        Website, the Services and Products offered on or off the website,
        Purchase of Services and Products, Delivery of Services and Products,
        Payments for transactions on or off the Website, and other transactions
        on or off the Website.
      </p>
      <p>
        This website <a href="https://rootrs.com"> www.rootrs.com </a>
        is owned by or licensed to RootRS INC and any reproduction is
        prohibited.
      </p>
      <p>
        As referenced in this agreement, "Site" means the website operated by
        the Company and currently located at
        <a href="https://rootrs.com"> www.rootrs.com</a>, as it may be updated,
        relocated, or otherwise modified from time to time, and includes any and
        all information, text, images, and other materials and content appearing
        on or incorporated into the website.
      </p>
      <p>
        The terms "RootRS INC" or "Service" mean the Site or any other web
        sites, networks, embeddable widgets, downloadable software, mobile
        applications including tablet computer applications (each an "App"),
        features, messages such as emails that we may send you, and services,
        whether on-line or off-line, owned or operated by the Company.
      </p>
      <p>
        Your access to and use of the Services is expressly conditioned on your
        acceptance to and compliance with these Terms and Conditions.
      </p>
      <p>
        Terms and Conditions apply to all visitors, users and others who access
        or use the Services provided by RootRS INC via this Website or
        otherwise. Terms and Conditions of Service, together with the terms and
        conditions contained in the "Privacy Policy" and the “Disclaimer
        Clauses” shall constitute an Agreement between the individual using the
        service and RootRS INC.
      </p>
      <p>
        All the Terms and Conditions applicable to the user are equally
        applicable and binding on its beneficiaries.
      </p>
      <p>
        By using this site, you acknowledge that you agree that you have read,
        understood, and agree to be bound by the Terms and Conditions of
        Service, as updated from time to time, and acknowledge that you have
        read and understood the contents of our Privacy Policy (available at:
        <a href="https://rootrs.com"> www.rootrs.com</a>), as updated from time
        to time.
      </p>
      <p>
        Upon Acceptance, these Terms and Conditions of Service will constitute a
        legal, valid, binding Agreement between the individual using the service
        (“you”, “Yours”, “Yourself”) and RootRS INC.
      </p>
      <h5>User Eligibility </h5>
      <p>
        Use of this Website is available only to persons who can legally
        contract under Indian Contract Act, 1872.
      </p>
      <p>
        Persons below the age of eighteen (18) years, un-discharged insolvents
        etc, are advised to transact through their parents or legal guardian.
      </p>
      <p>
        In the event, it is brought to our notice that the website is accessed
        by anyone not eligible by law, we reserve the right to terminate such
        membership or prevent such access.
      </p>
      <p>
        It is agreed that User and beneficiary shall be the point of contacts
        for RootRS INC for all matters relating to the services offered by
        RootRS INC.
      </p>
      <p>
        The user undertakes to provide all material information for purposes of
        availing service from RootRS INC. The user (even though such user may be
        a resident of a jurisdiction different from the place of delivery of
        Products, or Services), agrees to procure and obtain valid consent of
        the end beneficiaries of the Products or Services.
      </p>
      <p>
        RootRS INC will assume that the information submitted by the user at any
        time through the website or through other channels is factually accurate
        and updated at all times.
      </p>
      Any failure or omission on part of the user in rendering true facts or
      disclosing material facts shall result in immediate cancellation of the
      Service. The user shall be responsible to the Service provider for any
      loss incurred due to the said default and RootRS INC shall not be held
      responsible for the same at any point in time.
      <p>
        User agrees and acknowledges to receive promotional content from RootRS
        INC through the website or through other channels.
      </p>
      <h5>Account Creation and Security </h5>
      <p>
        User and/or beneficiaries are responsible for safeguarding relevant
        password to access this Website and to process any transactions or
        activities through RootRS INC or a third-party Service provider
        associated with RootRS INC.
      </p>
      <p>
        User and/or beneficiaries are responsible to notify RootRS INC if any
        breach of security or unauthorized use of account is suspected.
      </p>
      <p>
        RootRS INC shall not be responsible for any loss of data stored in the
        user account due to negligence of the user in handling the account and
        safeguarding the password.
      </p>
      <p>
        User and/or beneficiary are accessing this Website and transacting at
        their own risk and shall use their best and prudent judgment before
        entering into any transaction through this Website.
      </p>
      <h5>Services and Products offered </h5>
      <h5>General Service Requests By NRI's for</h5>
      <h6>Parents</h6>
      <ul>
        <li>Celebrating Birthdays & anniversaries - Any milestone</li>
        <li>Set up doctor appointments & take them - health care</li>
        <li>Home cooked meals</li>
        <li>Travel arrangement/companion within city</li>
        <li>Decorate home for functions</li>
        <li>Send chefs for festivities</li>
        <li>Festival food / Snacks & savories</li>
        <li>Arrange for Car & Driver to help travel</li>
        <li>Daily grocery or veggies delivery</li>
        <li>Electricity bill, water & sewer bill</li>
        <li>Plumbing</li>
        <li>Electrician</li>
        <li>Water tank Cleaning</li>
        <li>Carpenter</li>
        <li>Banking assistance</li>
        <li>Car servicing</li>
        <li>Motor cycle servicing</li>
        <li>Registered nurses to do basic checks</li>
        <li>Home massage</li>
        <li>Beauty & self care services</li>
        <li>Hair cut</li>
        <li>Urgent maid / home helper service</li>
        <li>Gardening service</li>
        <li>Cleaning service</li>
        <li>Moving homes & set up</li>
        <li>Specific Religious help</li>
        <li>Tailor / dress alteration</li>
        <li>AC mechanic</li>
        <li>
          Appliances repair - Washing machine, blender(mixie), wet grinder
        </li>
        <li>
          Travel Ticket booking - Pilgrimage, Train/Bus ticket, movie ticket
        </li>
        <li>US Travel escort - A to Z</li>
        <li>Laptop/iPhone set up - TechDesk help</li>
        <li>Car cleaning & gas</li>
        <li>Buy Wedding gift</li>
        <li>ENT Vision & Dentist check up & help</li>
        <li>Repair Replace Eye Glasses & Hearing Aid</li>
        <li>Property management</li>
        <li>Arrange / Advertise for Blood donation</li>
        <li>Arrange Funeral services</li>
        <li>Arrange for Ambulance</li>
        <li>Phone top-up / recharge / internet etc</li>
        <li>Pick up / drop - airport or railway station</li>
        <li>Arrange for Artist to perform at events</li>
        <li>Tenant management - Move In - Move out services</li>
        <li>Painting</li>
        <li>Traveling to India service - Arrange for stay etc</li>
        <li>Personalized shopping</li>
        <li>Temple Jewelry (shopping services)</li>
        <li>Hands-free shopping (someone to carry the stuff bought)</li>
      </ul>
      <h6>Sibling</h6>
      <ul>
        <li>Celebrating Birthdays & anniversaries - Any milestone</li>
        <li>Pay Tuition fee</li>
        <li>Motor cycle servicing</li>
        <li>Vehicle servicing (Redundant)</li>
        <li>Bride & Groom Background checks</li>
        <li>Event Planning - Photographer, Catering, venue decoration</li>
      </ul>
      <h6>Spouse & children</h6>
      <ul>
        <li>Tutoring services</li>
        <li>Laundry & Dress Ironing services</li>
        <li>(Redundant) Beauty & self care services</li>
        <li>Pay house rent & HOA</li>
        <li>Overseas legal services</li>
        <li>College admissions</li>
        <li>
          Complete relocation service (including sending their personal
          belongings from here)
        </li>
      </ul>
      <h5>Third Party Products and Services </h5>
      <p>
        RootRS INC shall facilitate and provide Services such as cleaning,
        plumbing, electrical, home safety, gadget repairs, etc either by its own
        staff or through third- party Service providers.
      </p>
      <p>
        All third-party Service providers are independent contractors and RootRS
        INC shall only act as a mediator/facilitator for booking and registering
        the requisite Service for its users.
      </p>
      <p>
        Products and Services from third-party Service providers are agreements
        between the user and the concerned Service Provider and RootRS INC is
        not a party to such Agreement.
      </p>
      <p>
        User undertakes not to directly or indirectly hire Services from third
        party Service providers availed through RootRS INC without prior
        intimation to RootRS INC.
      </p>
      <p>
        User undertakes to inform RootRS INC of changes sought in the Services
        rendered by the Service providers of RootRS INC. User undertakes to
        inform RootRS INC of changes sought in the Services rendered by the
        Service providers of RootRS INC. User undertakes to inform RootRS INC of
        changes sought in the Services rendered by the Service providers of
        RootRS INC.
      </p>
      <p>
        RootRS INC shall in no event be held liable for any accident or other
        incidents caused by the service providers, that are beyond the control
        of RootRS INC.
      </p>
      <p>
        In case of any emergency, arising due to the act/omission of the
        third-party Service provider, RootRS INC shall endeavour to provide
        assistance to the beneficiary to handle the situation and address the
        concerns, if any. However, RootRS INC shall not under any circumstance
        be liable for any issues caused by the service providers.
      </p>
      <p>
        Prior to obtaining services, the beneficiary shall be provided with
        tracking details for the specific Service to be obtained. Before
        commencement, an one-time-password based authentication shall be carried
        out to ensure authenticity of the service provider.
      </p>
      <p></p>
      <h5> Pricing </h5>
      <p>
        RootRS INC shall publish the types of Price plan, duration of service,
        promotional offers, and special discounts, on the website, or its
        application or through other channels as it may deem fit.
      </p>
      <p>
        User/beneficiary shall during the registration process exercise the
        option to choose the type of Price plan, and duration of service.
      </p>
      <p>
        RootRS INC shall take all precautions to ensure accurate pricing
        information is published on the website. In the event that a Service is
        listed at an incorrect price or incorrect information has been
        inadvertently published, RootRS INC may, at its discretion, either
        contact user/beneficiary for further instructions or cancel the request
        to avail such Service and notify user/beneficiary of such cancellation.
      </p>
      <p>
        RootRS INC shall take all precautions to ensure accurate pricing
        information is published on the website. In the event that a Service is
        listed at an incorrect price or incorrect information has been
        inadvertently published, RootRS INC may, at its discretion, either
        contact user/beneficiary for further instructions or cancel the request
        to avail such Service and notify user/beneficiary of such cancellation.
      </p>
      <p>
        RootRS INC reserves the right to modify the price of the Services from
        time to time without prior intimation to User/beneficiary. Such price
        modifications shall not have any impact on existing plans signed by the
        user/beneficiary and shall be applicable to future plans and Service
        requests from the user/beneficiary.
      </p>
      <p>
        Services rendered by third-party Service providers mediated/facilitated
        by RootRS INC are covered as per the price plans provided by the
        respective third-party service provider. RootRS INC shall not be
        responsible for incorrect price or incorrect information from
        third-party service providers.
      </p>
      <h5> Payment</h5>
      <p>
        All payments towards Services rendered by RootRS INC shall be made by
        User to the credit of RootRS INC, through the application or such other
        means as may be indicated at the time of making a booking for
        Services/Products.
      </p>
      <p>
        All payments towards Services rendered by third-party Service provider
        mediated/facilitated by RootRS INC shall be made by User to RootRS INC
        for onward remittance to the respective third-party.
      </p>
      <p>
        Payments shall be made by user/beneficiary to RootRS INC by cash,
        cheque, online via credit/debit card, RTGS, NEFT, Internet Banking, etc.
      </p>
      <p>
        Payments shall be made by user/beneficiary to third-party Service
        provider mediated/facilitated by RootRS INC as per the mode of payment
        stipulated by the third-party Service provider. RootRS INC shall not be
        liable in case of any dispute between the user and the third-party
        Service provider with respect to these payments.
      </p>
      <p>
        Online payments via credit/debit card, RTGS, NEFT, Internet Banking,
        etc., shall be subject to successful authorization from the card or
        account holders’ bank.
      </p>
      <p>
        Online payments via credit/debit card are processed by Razorpay payment
        gateway/Stripe.
      </p>
      <p>
        When processing any of the payment methods available on the Website,
        RootRS INC shall not be responsible or liable, whatsoever in respect of
        any loss or damage arising directly or indirectly to user/beneficiary
        due to whatever reasons.
      </p>
      <p>
        RootRS INC assures and acknowledges that all the information pertaining
        to credit/debit card available with RootRS INC shall not be utilised or
        shared with any third party unless required by law, regulation or court
        order.
      </p>
      <p>
        All online payments are processed using the gateway provided by the
        respective issuing banks which support payment facility. All such online
        payments are also governed by the terms and conditions agreed to between
        the user and the respective issuing bank.
      </p>
      <h5>Cancellation and Refund Policy</h5>
      <p>
        Requests for Services or Products from RootRS INC can be cancelled
        within 2 hours of placing the request. If payment was received by RootRS
        INC for these requests, full refund of money received will be made.
      </p>
      <p>
        For cancelling requests for Services or Products from RootRS INC beyond
        2 hours of placing the request, RootRS INC will cancel the request if
        cancellation is possible, and no action has been initiated for rendering
        service request or no action has been initiated for shipping the product
        request. If payment was received by RootRS INC for these requests,
        refund of money received will be made after deducting expenses, if any,
        incurred by RootRS INC.
      </p>
      <p>
        For cancelling request for Services or Products from third-party service
        provider mediated/facilitated by RootRS INC, RootRS INC will do its best
        to cancel the request. However, the cancellation and refund policy as
        per the agreements between the user and the concerned Service Provider
        shall apply for these cancellation requests.
      </p>
      <p>
        The process to initiate refund of money from RootRS INC shall be made
        only after ascertaining that the initial payment was received by RootRS
        INC.
      </p>
      <h5>Termination and Refund Policy </h5>
      <p>
        User shall exercise the right to terminate their enrollment with RootRS
        INC at any time during the sign-up period by giving at least 30 days
        prior written notice to RootRS INC.
      </p>
      <p>
        RootRS INC shall exercise the right of terminating the enrollment at any
        time during the sign-up period by giving at least 30 days prior written
        notice to the user/beneficiary. Such termination shall be without any
        liability to RootRS INC.
      </p>
      <p>
        Upon termination of enrollment, RootRS INC shall refund the plan
        charges. For this purpose, refund amount shall be calculated for the
        period remaining from the end of the month in which enrollment is
        withdrawn till the plan end date. Further unpaid amounts for services
        rendered or services requested shall be deducted and then amount
        refunded.
      </p>
      <h5>Limitation of Liability</h5>
      <p>
        RootRS INC shall not be liable to the user/beneficiary for any loss or
        damage from availing services either from RootRS INC or from Third-party
        Service provider mediated/facilitated by RootRS INC.
      </p>
      <p>
        Products and Services from third-party Service providers are agreements
        between the user and the concerned Service Provider and RootRS INC is
        not a party to such Agreements.
      </p>
      <p>
        User agrees that any fault or negligence or any default or deficiency or
        damage of goods committed in rendering the Services by the third-party
        Service provider shall be subjected to the terms and conditions of the
        third-party Service provider and the same shall not be attributed to
        RootRS INC.
      </p>
      <p>
        User agrees that any default or deficiency in rendering the Services by
        the third- party Service provider shall not be attributed to RootRS INC.
      </p>
      <p>
        RootRS INC shall not be liable for breach of terms and conditions on
        account of any delay in performing, or any failure to perform, if the
        delay or failure was due to any cause beyond RootRS INC’s reasonable
        control.
      </p>
      <h5>Force Majeure</h5>
      <p>
        Neither RootRS INC nor third-party Service providers shall be held
        liable for non- performance (either in whole or in part) or delay in its
        performance of their respective obligations, if caused by acts of God,
        acts of war or terrorist actions, inclement weather, fire, explosion or
        flood, strikes, work stoppages, slow-downs or other industrial disputes,
        riots or civil disturbances, acts of government, inability to obtain any
        necessary licence or consent, downtime of the server, or interruption or
        failure of electricity or telephone Service and any other event which
        could not reasonably be foreseen and is beyond the reasonable control of
        RootRS INC or the Service Providers.
      </p>
      <h5> Indemnity</h5>
      <p>
        You agree that you will be personally responsible for your use of the
        Service, and you agree to defend, indemnify and hold harmless RootRS INC
        and its officers, directors, employees, consultants, affiliates,
        subsidiaries and agents (collectively, the Company Entities) from and
        against any and all claims, liabilities, damages, losses and expenses,
        including reasonable legal and accounting fees and costs, arising out of
        or in any way connected with (i) your access to, use of or alleged use
        of the Service; (ii) your violation of the Terms of Service or any
        representation, warranty, or agreements referenced herein; (iii) your
        violation of any third party right, including without limitation any
        intellectual property right, publicity, confidentiality, property or
        privacy right; (iv) any claim or allegation of a third party that RootRS
        INC has referred to you, to the extent based on any actual or alleged
        act or omission on your part or behalf, or any other claim or allegation
        arising as a result of such referral; and (v) any disputes or issues
        between you and any third party. We reserve the right, at our own
        expense, to assume the exclusive defence and control of any matter
        otherwise subject to indemnification by you, and in such case, you agree
        to cooperate with our defence of such claim. You will not, in any event,
        settle any claim or matter without our written consent.
      </p>
      <p>
        User/beneficiary hereby expressly release RootRS INC and/or its
        affiliates and/or any of its staff and/or employee and representatives
        from any cost, damage, liability or other consequence of any of the
        actions/inactions of the third-party Service provider and specifically
        waiver any claims or demands that user/beneficiary may have in this
        behalf under any statute, Agreement or otherwise.
      </p>
      <h5>Waiver</h5>
      <p>
        If RootRS INC fails to insist upon strict performance of your
        obligations under any of these Terms and Conditions, or if it fails to
        exercise any of the rights or remedies to which it is entitled under
        this Agreement, it will not constitute a waiver of such rights or
        remedies and will not relieve you from compliance with such obligations.
        No waiver by RootRS INC of any default will constitute a waiver of any
        subsequent default, and no waiver by RootRS INC of any of these Terms
        and Conditions will be effective unless it is expressly stated to be a
        waiver and is communicated in writing.
      </p>
      <h5>Confidentiality</h5>
      <p>
        The parties agree to hold each other’s Proprietary or Confidential
        Information in strict confidence. “Proprietary or Confidential
        Information” shall include, but is not limited to, written or oral
        Agreements, trade secrets, know-how, business methods, business
        policies, memoranda, reports, records, computer retained information,
        notes, or financial information.
      </p>
      <h5>Copyright and Trademark </h5>
      <p>
        The contents of this Website, such as text, design, graphics, logos,
        trade name, trade mark, audio clips, video, etc. and the contents of our
        Websites and associated Services, including text, files, images, photos,
        video, sounds, musical works, design, graphics, logos, trade dress,
        audio clips, photographs, software, interfaces, code, or other works of
        authorship (the "Content") and their selection and arrangement is the
        property of RootRS INC and/or of its associates and/or affiliates that
        have granted RootRS INC, the right and license to use the Content, and
        is protected by the relevant and applicable copyright and trademark
        laws, or other intellectual property rights. User/beneficiary shall not
        use the Content or any part thereof as own content and shall not
        reprint, republish or distribute any part of the Content in any form
        without the express written consent of RootRS INC.
      </p>
      <p>
        Nothing on this Website or your use of the Services shall be construed
        as conferring on user/beneficiary or any party, any licence or other
        rights under the intellectual property or other proprietary rights of
        RootRS INC, its affiliates or any third party, whether implied or
        otherwise.
      </p>
      <p>
        User/beneficiary shall not indulge in the act of copying, reproducing,
        transmitting, selling, distributing, license or create derivative works
        of the Content, from this Website or any portion hereof.
      </p>
      <p>
        User/beneficiary shall not indulge in modification or alteration of the
        Content in any manner whatsoever.
      </p>
      <p>
        User/beneficiary shall not use any of the Marks as own marks or as part
        of own marks or in any manner which is likely to cause confusion or to
        identify your own products or Services or to imply endorsement or
        otherwise of products or Services to which they do not relate or in any
        manner which may cause damage to the reputation of RootRS INC and/or its
        affiliates and/or associates.
      </p>
      <p>
        User/beneficiary shall not create or use any other mark that is
        deceptively similar to the Marks, be it for Your own use or for the use
        of any third party.
      </p>
      <p>
        Commercial use of any elements or information on RootRS INC’s Website,
        directly or otherwise, is strictly prohibited unless express written
        permission is obtained in advance.
      </p>
      <p>
        RootRS INC shall not be responsible for claims relating to infringement
        of any intellectual property rights in relation to the Service(s) and/or
        their content.
      </p>
      <h5>Modification and Severability</h5>
      <p>
        RootRS INC reserves its rights to modify these Terms and Conditions from
        time to time and the same shall be notified by means of the Website or
        by email. By continuing to access or use the Service after we have
        posted or emailed any such modifications, you agree to be bound by the
        modified Terms of Service (as applicable), but solely on a going forward
        basis.
      </p>
      <p>
        All the amendments/variations made to this Agreement in future shall be
        read as part of this Agreement unless otherwise intended.
      </p>
      <p>
        If any clause of this Agreement is held by a court of competent
        jurisdiction to be contrary to law, such provision shall be changed and
        interpreted so as to best accomplish the objectives of the original
        provision to the fullest extent allowed by law and the remaining
        provisions of this Policy will remain in full force and effect.
      </p>
      <h5>Governing Law and Dispute Resolution</h5>
      <p>
        The arrangement/Agreement/association between RootRS INC and the users
        and its beneficiaries shall be construed in accordance with the
        applicable laws of state of New Jersey. The Courts at state of New
        Jersey. shall have sole and exclusive jurisdiction in any proceedings
        arising out of this arrangement/Agreement/association.
      </p>
      <p>
        All disputes arising between the parties as to the interpretation,
        operation, or effect of any clause in this agreement or any other
        difference arising between the parties, which cannot be mutually
        resolved, shall be referred to the arbitration. The Arbitration panel
        shall consist of a single arbitrator and the venue of arbitration shall
        be at state of New Jersey.. The decision of the arbitrator/s shall be
        binding on the parties. The arbitration shall be governed by the
        provisions of state of New Jersey & Federal States of America.
      </p>
    </div>
  );
};

export default TermsOfConditions;
