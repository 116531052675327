import React from "react";
import { Link } from "react-router-dom";
// import Footer_Logo from ".";
import TermsOfConditions from "../Pages/TermsOfConditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import AppLinkButtons from "./AppLinkButtons/AppLinkButtons";
// import PrivacyPolicy from "../Pages/PrivacyPolicy";

export const Footer = () => {
  return (
    <>
      <div className="footer bg-secondary py-5">
        <div className="container pt-md-2 pt-lg-3 pt-xl-4">
          <div className="row pb-1 pb-md-3 pt-sm-2 mb-lg-2 d-flex justify-content-center">
            <div className="col-sm-4 col-md-4 col-lg-3 pb-2 pb-md-0 mb-4 mb-md-0 text-center">
              <Link
                to={"/"}
                className="navbar-brand text-nav py-0 mb-3 mb-md-4 mx-auto d-flex justify-content-center"
              >
                <span className="text-primary flex-shrink-0 me-2" />
                <img
                  // src="./VerticalLogo.png"
                  // src={Footer_Logo}
                  src="./VerticalLogo.png"
                  width="70%"
                  alt=""
                  style={{ maxWidth: "111px" }}
                />
              </Link>

              <div className="fs-sm">
                Delivering Emotions Worldwide - Connecting Hearts, One Service
                at a Time
              </div>
              <AppLinkButtons small={true} />

              {/* social links */}
              <div className="d-flex justify-content-center mo130298">
                <a
                  className="btn btn-icon btn-sm btn-secondary btn-facebook rounded-circle me-3"
                  href="/"
                >
                  <i className="ai-facebook"></i>
                </a>
                <a
                  className="btn btn-icon btn-sm btn-secondary btn-instagram rounded-circle me-3"
                  href="/"
                >
                  <i className="ai-instagram"></i>
                </a>
                <a
                  className="btn btn-icon btn-sm btn-secondary btn-linkedin rounded-circle"
                  href="/"
                >
                  <i className="ai-linkedin"></i>
                </a>
              </div>
            </div>

            {/*  */}
            {/* <div className="col-sm-8 col-md-8 col-lg-7 col-xl-6 offset-lg-2 offset-xl-3">
              <div className="row row-cols-3">
                <div className="col mb-3 mb-md-0">
                  <ul className="nav flex-column">
                    <li>
                      <a className="nav-link fw-normal py-1 px-0" href="/">
                        Features
                      </a>
                    </li>
                    <li>
                      <a className="nav-link fw-normal py-1 px-0" href="/">
                        How it works
                      </a>
                    </li>
                    <li>
                      <a className="nav-link fw-normal py-1 px-0" href="/">
                        Reviews
                      </a>
                    </li>
                    <li>
                      <a className="nav-link fw-normal py-1 px-0" href="/">
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col mb-4 mb-md-0">
                  <ul className="nav flex-column">
                    <li>
                      <a className="nav-link fw-normal py-1 px-0" href="/">
                        Help center
                      </a>
                    </li>
                    <li>
                      <a className="nav-link fw-normal py-1 px-0" href="/">
                        Terms of service
                      </a>
                    </li>
                    <li>
                      <a className="nav-link fw-normal py-1 px-0" href="/">
                        Legal
                      </a>
                    </li>
                    <li>
                      <a className="nav-link fw-normal py-1 px-0" href="/">
                        Privacy policy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <a
                    className="btn btn-secondary px-3 py-2 mb-3 me-3 me-md-0"
                    href="/"
                  >
                    <img
                      className="mx-1 d-dark-mode-none"
                      src="assets/img/market/appstore-dark.svg"
                      width="120"
                      alt="App Store"
                    />
                    <img
                      className="mx-1 d-none d-dark-mode-block"
                      src="assets/img/market/appstore-light.svg"
                      width="120"
                      alt="App Store"
                    />
                  </a>
                  <a
                    className="btn btn-secondary px-3 py-2 mb-3 me-3 me-md-0"
                    href="/"
                  >
                    <img
                      className="mx-1 d-dark-mode-none"
                      src="assets/img/market/googleplay-dark.svg"
                      width="119"
                      alt="Google Play"
                    />
                    <img
                      className="mx-1 d-none d-dark-mode-block"
                      src="assets/img/market/googleplay-light.svg"
                      width="119"
                      alt="Google Play"
                    />
                  </a>
                </div>
              </div>
            </div> */}
          </div>

          {/*  */}
          <p className="text-center">
            <Link to="/terms" element={<TermsOfConditions />}>
              Terms Of Conditions
            </Link>
          </p>
          <p className="text-center">
            <Link to="/privacy" element={<PrivacyPolicy />}>
              Privacy Policy
            </Link>
          </p>
          <p className="fs-sm mb-0 text-center">
            <span className="opacity-70">
              &copy; All rights reserved | RootRS {new Date().getFullYear()}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
