import React from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./assets/styles/style.css";
import Slider_1 from "../../../src/banners-01.png";
import Slider_2 from "../../../src/banners-02.png";
// import Slider_3 from "../../../src/banners-05.png";

const SliderModule = () => {
  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonial text-center">
        <Container>
          <Slider {...settings}>
            <Col>
              <Card className="mx-2 my-5">
                <Card.Img
                  variant="top"
                  src={Slider_1}
                  style={{ borderRadius: "16px" }}
                />
              </Card>
            </Col>
            <Col>
              <Card className="mx-2 my-5">
                <Card.Img
                  variant="top"
                  src={Slider_2}
                  style={{ borderRadius: "16px" }}
                />
              </Card>
            </Col>
            <Col>
              <Card className="mx-2 my-5">
                <Card.Img
                  variant="top"
                  src={Slider_1}
                  style={{ borderRadius: "16px" }}
                />
              </Card>
            </Col>
            <Col>
              <Card className="mx-2 my-5">
                <Card.Img
                  variant="top"
                  src={Slider_2}
                  style={{ borderRadius: "16px" }}
                />
              </Card>
            </Col>
          </Slider>
        </Container>
      </section>
    </>
  );
};

export default SliderModule;

// import Carousel from "react-bootstrap/Carousel";
// import Slider_1 from "../../slider_1.webp";

// function SliderModule() {
//   return (
//     <Carousel>
//       <Carousel.Item>
//         <img className="d-block w-100" src={Slider_1} alt="First slide" />
//         <Carousel.Caption>
//           <h1 className="text-white">First</h1>
//           <h3 className="text-white">First slide label</h3>
//           <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
//         </Carousel.Caption>
//       </Carousel.Item>

//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           //   src="holder.js/800x400?text=Third slide&bg=20232a"
//           src={Slider_1}
//           alt="Third slide"
//         />

//         <Carousel.Caption>
//           <h3 className="text-white">Third slide label</h3>
//           <p>
//             Praesent commodo cursus magna, vel scelerisque nisl consectetur.
//           </p>
//         </Carousel.Caption>
//       </Carousel.Item>
//     </Carousel>
//   );
// }

// export default SliderModule;
