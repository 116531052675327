// import React from "react";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FormModule from "../../Pages/FormPage";
import "./assets/styles/style.css";
import { Backdrop } from "@mui/material";

const Services = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    phone: "",
    name: "",
    email: "",
    location: "",
    servicingFor: "",
    servicingLocation: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //
    const url = "https://api.root.delivery";

    axios
      .post(`${url}/user/betaUser`, values)
      .then((res) => {
        // navigate("/submit");
        window.location.href = "/submit";
      })
      .catch((err) => {
        alert("Sorry Error in Submitting, try later");
      });
  };
  return (
    <>
      {/* <!-- our services --> */}
      <section className="container pt-5 mt-lg-3 mt-xl-4 mt-xxl-5 services">
        <div className="row">
          <div className="col-lg-4 pt-2">
            <a
              data-bs-toggle="modal"
              data-bs-target="#organic"
              className="card card-hover-primary bg-secondary border-0 text-decoration-none"
            >
              <div className="card-body pb-0">
                <h3 className="h4 card-title mt-0">Routine Chores</h3>
                <li className="list-group-item">Frequent Checking</li>
                <li className="list-group-item">Electrical</li>
                <li className="list-group-item">Painting</li>
              </div>
              <div className="card-footer border-0 py-3 my-3 mb-sm-4">
                <button type="button" className="btn btn-lg btn-primary">
                  See More
                </button>
              </div>
            </a>
          </div>
          <div className="col-lg-4 pt-2">
            <a
              data-bs-toggle="modal"
              data-bs-target="#unorganised"
              className="card card-hover-primary bg-secondary border-0 text-decoration-none"
            >
              <div className="card-body pb-0">
                <h3 className="h4 card-title mt-0">Unorganized Chores</h3>
                <li className="list-group-item">Rent Collection/Payment</li>
                <li className="list-group-item">Personal Shopping</li>
                <li className="list-group-item">Shop & Ship[Courier]</li>
              </div>
              <div className="card-footer border-0 py-3 my-3 mb-sm-4">
                <button type="button" className="btn btn-lg btn-primary">
                  See More
                </button>
              </div>
            </a>
          </div>
          <div className="col-lg-4 pt-2">
            <a
              data-bs-toggle="modal"
              data-bs-target="#services"
              className="card card-hover-primary bg-secondary border-0  text-decoration-none"
            >
              <div className="card-body pb-0">
                <h3 className="h4 card-title mt-0">Check In</h3>
                <li className="list-group-item">Bill Payment</li>
                <li className="list-group-item">Rent Collection</li>
                <li className="list-group-item">Regular Checking</li>
              </div>
              <div className="card-footer border-0 py-3 my-3 mb-sm-4">
                <button type="button" className="btn btn-lg btn-primary">
                  See More
                </button>
              </div>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- our services end --> */}
      {/* <!-- modal tabs --> */}
      <div className="modal fade" tabIndex="-1" id="organic" role="dialog">
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          role="document"
          data-bs-config="backdrop:true"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Routine Chores</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ul className="list-group list-group-flush">
                {/* <li className="list-group-item">Partner</li>
                <li className="list-group-item">Business(small/medium)</li>
                <li className="list-group-item">freelancers</li> */}
                <li className="list-group-item">Rent Collection</li>
                <li className="list-group-item">Electrical</li>
                <li className="list-group-item">Painting</li>
                <li className="list-group-item">Carpentary</li>
                <li className="list-group-item">Equipment Installation</li>
                <li className="list-group-item">Tech Support</li>
                <li className="list-group-item">Pick Up</li>
                <li className="list-group-item">Drop</li>
                <li className="list-group-item">Local Travel Booking</li>
                <li className="list-group-item">Food Delivery</li>
                <li className="list-group-item">
                  Hospital Visits for Check Up/Doctor Visits
                </li>
                <li className="list-group-item">
                  SOS Emergency Services, Ambulance
                </li>
                <li className="list-group-item">Home Tasks for Health Care</li>
                {/* <li className="list-group-item">health care</li>
                <li className="list-group-item">driver</li>
                <li className="list-group-item">cook</li>
                <li className="list-group-item">car care</li>
                <li className="list-group-item">motor cycle care</li>
                <li className="list-group-item">car wash</li>
                <li className="list-group-item">car regular service</li>
                <li className="list-group-item">puncture service</li>
                <li className="list-group-item">
                  international tour booking with family
                </li>
                <li className="list-group-item">
                  regular ticket booking - rail/bus/flight
                </li>
                <li className="list-group-item">passport & visa service</li>
                <li className="list-group-item">forex</li>
                <li className="list-group-item">send money</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex="-1" id="unorganised" role="dialog">
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Unorganized Chores</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ul className="list-group list-group-flush">
                {/* <li className="list-group-item">Runners</li> */}
                <li className="list-group-item">Rent Collection/Payment</li>
                <li className="list-group-item">Personal Shopping</li>
                <li className="list-group-item">Shop & Ship[Courier]</li>
                <li className="list-group-item">Local Travel Booking</li>
                <li className="list-group-item">Home Tasks for Health Care</li>
                <li className="list-group-item">Grocery Delivery</li>
                <li className="list-group-item">Packing & Moving[Home]</li>
                <li className="list-group-item">Simple Household Errands</li>
                <li className="list-group-item">Household Cleaning</li>
                <li className="list-group-item">Shop & Drop Off</li>
                <li className="list-group-item">Bill Payments</li>
                <li className="list-group-item">Driver</li>
                <li className="list-group-item">Cook</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex="-1" id="services" role="dialog">
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Check In</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Bill Payment</li>
                <li className="list-group-item">Rent Collection</li>
                <li className="list-group-item">Regular Checking</li>
                {/* <li className="list-group-item">Electrical</li>
                <li className="list-group-item">Paiting</li>
                <li className="list-group-item">Carpentary</li>
                <li className="list-group-item">Equipment Installation</li> */}
                <li className="list-group-item">Personal Shopping</li>
                <li className="list-group-item">Shop & Ship</li>
                <li className="list-group-item">Tech Support</li>
                <li className="list-group-item">Pick up</li>
                <li className="list-group-item">Drop Up</li>
                <li className="list-group-item">Local Travel Booking</li>
                <li className="list-group-item">Food Delivery</li>
                <li className="list-group-item">
                  Taking for Hospital for Check Up
                </li>
                {/* <li className="list-group-item">
                  SOS Emergency Services, Ambulance
                </li> */}
                <li className="list-group-item">Home Tasks for Health Care</li>
                <li className="list-group-item">Grocery Delivery</li>
                {/* <li className="list-group-item">Home Moving</li>
                <li className="list-group-item">Health Care</li> */}
                <li className="list-group-item">Driver</li>
                <li className="list-group-item">Cook</li>
                <li className="list-group-item">Household Cleaning</li>
                <li className="list-group-item">Car Care</li>
                <li className="list-group-item">Motor Cycle Care</li>
                <li className="list-group-item">Shop & Drop off</li>
                <li className="list-group-item">Car Wash</li>
                <li className="list-group-item">Car Regular Service</li>
                {/* <li className="list-group-item">Puncture Service</li>
                <li className="list-group-item">
                  International Tour Booking With Family
                </li>
                <li className="list-group-item">
                  Regular Ticket Booking - Rail/Bus/Flight
                </li>
                <li className="list-group-item">Passport & Visa Service</li> */}
                <li className="list-group-item">Forex</li>
                <li className="list-group-item">Send Money</li>
                {/* <li className="list-group-item">Deliver Cash Up to 10k</li>
                <li className="list-group-item">Other</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- modal tabs end --> */}

      {/* form modal */}
      <div className="modal fade" tabIndex="-1" id="betaUserForm" role="dialog">
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Sign up as early users!</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row pt-0">
                <div className="row justify-content-center mb-5">
                  <form className="col-12" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6  pb-3">
                        <label className="form-label fs-base" htmlFor="phone">
                          Your Name
                        </label>
                        <input
                          className="form-control form-control-lg"
                          name="name"
                          type="text"
                          placeholder="Enter your Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6  pb-3">
                        <label className="form-label fs-base" htmlFor="phone">
                          Your E-mail
                        </label>
                        <input
                          className="form-control form-control-lg"
                          name="email"
                          type="email"
                          placeholder="Enter your e-mail"
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6  pb-3">
                        <label className="form-label fs-base" htmlFor="phone">
                          Your Phone Number
                        </label>
                        <input
                          className="form-control form-control-lg"
                          name="phone"
                          type="text"
                          placeholder="Phone number with country code"
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6  pb-3">
                        <label
                          className="form-label fs-base"
                          htmlFor="location"
                        >
                          Your Location
                        </label>
                        <input
                          className="form-control form-control-lg"
                          name="location"
                          type="text"
                          placeholder="Enter your Location"
                          required
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-6 pb-3">
                        <label
                          className="form-label fs-base"
                          htmlFor="location"
                        >
                          Servicing for
                        </label>
                        <select
                          className="form-select form-select-lg"
                          id="location"
                          name="servicingFor"
                          required
                          onChange={handleChange}
                        >
                          <option
                            hidden
                            label="Select Servicing for"
                            value=""
                          ></option>
                          <option value="self">Self</option>
                          <option value="family">Family</option>
                          <option value="friends">Friends</option>
                        </select>
                      </div>

                      <div className="col-lg-6 pb-3">
                        <label
                          className="form-label fs-base"
                          htmlFor="location"
                        >
                          Servicing Location
                        </label>
                        <select
                          className="form-select form-select-lg"
                          id="location"
                          name="servicingLocation"
                          required
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          <option
                            hidden
                            label="Select Servicing Location"
                            value=""
                          ></option>
                          <option value="ahmedabad">Ahmedabad</option>
                          <option value="bangalore">Bangalore</option>
                          <option value="bhavnagar">Bhavnagar</option>
                          <option value="bhopal">Bhopal</option>
                          <option value="bhuvaneshwar">Bhuvaneshwar</option>
                          <option value="bokoroSteelCity">
                            bokoro Steel City
                          </option>
                          <option value="chennai">Chennai</option>
                          <option value="coimbatore">coimbatore</option>
                          <option value="chandigarh">chandigarh</option>
                          <option value="dehradun">dehradun</option>
                          <option value="delhi">Delhi</option>
                          <option value="erode">Erode</option>
                          <option value="guntur">guntur</option>
                          <option value="gurugram">gurugram</option>
                          <option value="hyderabad">Hyderabad</option>
                          <option value="indore">indore</option>
                          <option value="jamnagar">jamnagar</option>
                          <option value="jodhpur">jodhpur</option>
                          <option value="kakinada">kakinada</option>
                          <option value="kochi">kochi</option>
                          <option value="kolkata">Kolkata</option>
                          <option value="kurnool">kurnool</option>
                          <option value="madurai">madurai</option>
                          <option value="mumbai">Mumbai</option>
                          <option value="nellore">nellore</option>
                          <option value="patna">patna</option>
                          <option value="rajamahendravaram">
                            rajamahendravaram
                          </option>
                          <option value="rajkot">rajkot</option>
                          <option value="rourkela">rourkela</option>
                          <option value="salem">salem</option>
                          <option value="surat">surat</option>
                          <option value="thanjavur">thanjavur</option>
                          <option value="thiruchirappalli">
                            thiruchirappalli
                          </option>
                          <option value="thirunelveli">thirunelveli</option>
                          <option value="tiruppur">tiruppur</option>
                          <option value="tiruvannamalai">tiruvannamalai</option>
                          <option value="tiruvendrum">tiruvendrum</option>
                          <option value="udhaipur">udhaipur</option>
                          <option value="vadodara">vadodara</option>
                          <option value="vellore">vellore</option>
                          <option value="vijayawada">vijayawada</option>
                          <option value="visakhapatnam">visakhapatnam</option>
                          <option value="warangal">warangal</option>
                        </select>
                      </div>
                    </div>
                    <div className="pt-3" style={{ textAlign: "center" }}>
                      <button className="btn btn-lg btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* form modal end */}
      {/* form modal */}
      <div className="modal fade" tabIndex="-1" id="getAppLink" role="dialog">
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          role="document"
          data-bs-config="backdrop:true"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Sign up as early users!</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <FormModule />
            </div>
          </div>
        </div>
      </div>
      {/* form modal end */}
    </>
  );
};

export default Services;
